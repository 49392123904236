import { Component, OnInit } from '@angular/core';
import { BaseLayoutComponent } from 'src/app/shared/base/layout/base-layout.component';
import { LoginModel } from 'src/app/model/login.model';

@Component({
  selector: 'client-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class ClientLoginComponent extends BaseLayoutComponent implements OnInit {
  username = '';
  password = '';
  loginModel: LoginModel;
  query = '';

  response = '';
  isAuthenticated = false;
  receiveAuthenticated = ($event: boolean) => this.isAuthenticated = $event;
  receiveResponse = ($event: string) => this.response = $event;

  login() {
    if (this.username.trim() == '' || this.password.trim() == '') {
      this.toastrService.error(this.rss.username + ', ' + this.rss.password + ' ' + this.rss.cantbeblank);
      return;
    }

    this.subjectService.reloadgooglerecaptcha$.next(false);
    if (!this.isAuthenticated) {
      this.toastrService.warning(this.rss.captchaisnotauthenticated);
      return;
    }

    this.loginModel = { Username: this.username, Password: this.password, ReCaptchaResponse: this.response };
    this.loginService.login(this.loginModel).subscribe(
      data => {
        if (this.validateLogin(data.value)) {
          if (this.query && this.query != '')
            window.location.href = `/${this.query}`;
          else
            window.location.href = `/admin`;
        }
      },
      error => console.log(error)
    );
  }

  private validateLogin(value: string): boolean {
    let error = '';

    if (value == '0')
      error = this.rss.captchaisnotauthenticated;
    else if (value == '-1')
      error = this.rss.youraccountdoesnotexist;
    else if (value == '-2')
      error = this.rss.yourloginnameorpasswordisincorrect;
    else if (value == '-3')
      error = this.rss.youraccountisblocked;
    else
      this.toastrService.success(this.rss.loginsuccessfully);

    if (error != '') {
      this.toastrService.error(error);
      return false;
    }
    this.subjectService.reloadgooglerecaptcha$.next(true);
    return true;
  }
}
