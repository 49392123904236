import { Component, OnInit } from '@angular/core';
import { ClientLayoutComponent } from 'src/app/shared/client/layout/layout.component';
import { SimpleContentModel } from 'src/app/model/simplecontent.model';

@Component({
  selector: 'client-overview',
  templateUrl: './overview.component.html'
})
export class ClientOverviewComponent extends ClientLayoutComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.controller = 'content';
    
    that.subjectService.currentLanguageCode$.subscribe(lang => new SimpleContentModel([]).changeListSimpleContentLanguage(that.listData, lang));
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.activatedRoute.queryParams.subscribe(params => {
      that.pageSize = that.currentContent.take;
      that.queryString = [
        { Key: 'page', Value: that.page },
        { Key: 'pageSize', Value: that.currentContent.take },
        { Key: 'id', Value: that.currentContent.id },
        { Key: 'templateid', Value: that.currentContent.templateId },
        { Key: 'keyword', Value: that.keyword }
      ];
      that.getPage();
    });
  }
}
