import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, UrlSerializer } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ckeditor4-angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CookieService } from 'ngx-cookie-service';
import { OwlModule } from 'ngx-owl-carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgProgressModule } from '@ngx-progressbar/core';
import { ShareButtonsModule } from '@ngx-share/buttons';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToastrModule } from 'ngx-toastr';

import { Link } from './others/link';
import { LowerCaseUrlSerializer } from './others/lowercaseurlserializer';

import { PermissionService } from './service/permission.service';
import { AppService } from './service/app.service';
import { AppComponent } from './app.component';

import { BaseLayoutComponent } from './shared/base/layout/base-layout.component';
import { AdminLayoutComponent } from './shared/admin/layout/layout.component';
import { AdminNavigationComponent } from './shared/admin/navigation/navigation.component';
import { AdminSidebarComponent } from './shared/admin/sidebar/sidebar.component';
import { ClientLayoutComponent } from './shared/client/layout/layout.component';
import { ClientFooterComponent } from './shared/client/footer/footer.component';
import { ClientHeaderComponent } from './shared/client/header/header.component';

import { AdminContentComponent } from './area/admin/content/content.component';
import { AdminContentInfoComponent } from './area/admin/contentinfo/contentinfo.component';
import { AdminContentEditImageComponent } from './area/admin/contenteditimage/contenteditimage.component';
import { AdminContentEditCategoryComponent } from './area/admin/contenteditcategory/contenteditcategory.component';
import { AdminFeedbackComponent } from './area/admin/feedback/feedback.component';
import { AdminFeedbackEditComponent } from './area/admin/feedbackedit/feedbackedit.component';
import { AdminHomeComponent } from './area/admin/home/home.component';
import { AdminLinkComponent } from './area/admin/link/link.component';
import { AdminLinkInfoComponent } from './area/admin/linkinfo/linkinfo.component';
import { AdminMasterDataComponent } from './area/admin/masterdata/masterdata.component';
import { AdminPermissionComponent } from './area/admin/permission/permission.component';
import { AdminPermissionInfoComponent } from './area/admin/permissioninfo/permissioninfo.component';
import { AdminTagComponent } from './area/admin/tag/tag.component';
import { AdminTagInfoComponent } from './area/admin/taginfo/taginfo.component';
import { AdminUserComponent } from './area/admin/user/user.component';
import { AdminUserEditAuthorizeComponent } from './area/admin/usereditauthorize/usereditauthorize.component';
import { AdminUserEditPasswordComponent } from './area/admin/usereditpassword/usereditpassword.component';
import { AdminUserInfoComponent } from './area/admin/userinfo/userinfo.component';

import { ClientAboutComponent } from './area/client/about/about.component';
import { ClientContactComponent } from './area/client/contact/contact.component';
import { ClientHomeComponent } from './area/client/home/home.component';
import { ClientLoginComponent } from './area/client/login/login.component';
import { ClientNewsComponent } from './area/client/news/news.component';
import { ClientNewsDetailComponent } from './area/client/newsdetail/newsdetail.component';
import { ClientOverviewComponent } from './area/client/overview/overview.component';
import { ClientProjectComponent } from './area/client/project/project.component';
import { ClientProjectDetailComponent } from './area/client/projectdetail/projectdetail.component';
import { ClientVideoComponent } from './area/client/video/video.component';

import { CustomClientAllProjectsComponent } from './area/custom/client/allprojects/allprojects.component';
import { CustomClientAnalysisComponent } from './area/custom/client/analysis/analysis.component';
import { CustomClientBannerComponent } from './area/custom/client/banner/banner.component';
import { CustomClientCategoriesComponent } from './area/custom/client/categories/categories.component';
import { CustomClientCategoriesAnchorComponent } from './area/custom/client/categoriesanchor/categoriesanchor.component';
import { CustomClientCommentFacebookComponent } from './area/custom/client/commentfacebook/commentfacebook.component';
import { CustomClientNewestPostsComponent } from './area/custom/client/newestposts/newestposts.component';
import { CustomClientOtherContentsComponent } from './area/custom/client/othercontents/othercontents.component';
import { CustomClientShareTagComponent } from './area/custom/client/sharetag/sharetag.component';
import { CustomClientVideoAboutComponent } from './area/custom/client/videoabout/videoabout.component';
import { CustomGoogleRecaptchaComponent } from './area/custom/googlerecaptcha/googlerecaptcha.component';
import { NgMultiselectDropdownContentLinkComponent } from './area/custom/ngmultiselectdropdown/contentlink/contentlink.component';
import { NgMultiselectDropdownContentParentComponent } from './area/custom/ngmultiselectdropdown/contentparent/contentparent.component';
import { NgMultiselectDropdownContentTagComponent } from './area/custom/ngmultiselectdropdown/contenttag/contenttag.component';
import { NgxSlickCarouselListthumbForSlidernavComponent } from './area/custom/ngxslickcarousel/listthumbforslidernav/listthumbforslidernav.component';
import { NgxSlickCarouselSlidernavForListthumbComponent } from './area/custom/ngxslickcarousel/slidernavforlistthumb/slidernavforlistthumb.component';

import { Status404Component } from './area/status/404.component';

export function initSettings(as: AppService) {
  return () => as.loadSettings();
}

@NgModule({
  declarations: [
    AppComponent,
    
    BaseLayoutComponent,
    AdminLayoutComponent,
    AdminNavigationComponent,
    AdminSidebarComponent,
    ClientLayoutComponent,
    ClientFooterComponent,
    ClientHeaderComponent,
    
    AdminContentComponent,
    AdminContentInfoComponent,
    AdminContentEditImageComponent,
    AdminContentEditCategoryComponent,
    AdminFeedbackComponent,
    AdminFeedbackEditComponent,
    AdminHomeComponent,
    AdminLinkComponent,
    AdminLinkInfoComponent,
    AdminMasterDataComponent,
    AdminPermissionComponent,
    AdminPermissionInfoComponent,
    AdminTagComponent,
    AdminTagInfoComponent,
    AdminUserComponent,
    AdminUserEditAuthorizeComponent,
    AdminUserEditPasswordComponent,
    AdminUserInfoComponent,

    ClientAboutComponent,
    ClientContactComponent,
    ClientHomeComponent,
    ClientLoginComponent,
    ClientNewsComponent,
    ClientNewsDetailComponent,
    ClientOverviewComponent,
    ClientProjectComponent,
    ClientProjectDetailComponent,
    ClientVideoComponent,

    CustomClientAllProjectsComponent,
    CustomClientAnalysisComponent,
    CustomClientBannerComponent,
    CustomClientCategoriesComponent,
    CustomClientCategoriesAnchorComponent,
    CustomClientCommentFacebookComponent,
    CustomClientNewestPostsComponent,
    CustomClientOtherContentsComponent,
    CustomClientShareTagComponent,
    CustomClientVideoAboutComponent,
    CustomGoogleRecaptchaComponent,
    NgMultiselectDropdownContentLinkComponent,
    NgMultiselectDropdownContentParentComponent,
    NgMultiselectDropdownContentTagComponent,
    NgxSlickCarouselListthumbForSlidernavComponent,
    NgxSlickCarouselSlidernavForListthumbComponent,

    Status404Component
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    CKEditorModule, // ckeditor4-angular
    BrowserAnimationsModule, // toastr: required animations module
    NgProgressModule, // @ngx-progressbar/core
    ShareButtonsModule,
    OwlModule, // ngx-owl-carousel
    NgxPaginationModule, // ngx-pagination
    SlickCarouselModule,
    ToastrModule.forRoot(), // ngx-toastr
    LazyLoadImageModule,
    NgMultiSelectDropDownModule.forRoot(), // ng-multiselect-dropdown
    RouterModule.forRoot([])
  ],
  entryComponents: [
    AppComponent,

    BaseLayoutComponent,
    AdminLayoutComponent,
    AdminNavigationComponent,
    AdminSidebarComponent,
    ClientLayoutComponent,
    ClientFooterComponent,
    ClientHeaderComponent,

    AdminContentComponent,
    AdminContentInfoComponent,
    AdminContentEditImageComponent,
    AdminContentEditCategoryComponent,
    AdminFeedbackComponent,
    AdminFeedbackEditComponent,
    AdminHomeComponent,
    AdminLinkComponent,
    AdminLinkInfoComponent,
    AdminMasterDataComponent,
    AdminPermissionComponent,
    AdminPermissionInfoComponent,
    AdminTagComponent,
    AdminTagInfoComponent,
    AdminUserComponent,
    AdminUserEditAuthorizeComponent,
    AdminUserEditPasswordComponent,
    AdminUserInfoComponent,

    ClientAboutComponent,
    ClientContactComponent,
    ClientHomeComponent,
    ClientLoginComponent,
    ClientNewsComponent,
    ClientNewsDetailComponent,
    ClientOverviewComponent,
    ClientProjectComponent,
    ClientProjectDetailComponent,
    ClientVideoComponent,

    Status404Component
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    CookieService,
    DatePipe,
    AppService,
    Link,
    PermissionService,
    {
      'provide': APP_INITIALIZER,
      'useFactory': initSettings,
      'deps': [AppService],
      'multi': true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
