export class Resource {
  activated: string;
  add: string;
  address: string;
  all: string;
  alreadyamember: string;
  authorize: string;
  avatar: string;
  back: string;
  backtolist: string;
  backtotop: string;
  belongto: string;
  briefcontent: string;
  by: string;
  cantbeblank: string;
  captchaisnotauthenticated: string;
  categories: string;
  changepassword: string;
  changestatussuccessfully: string;
  checktheconsolelog: string;
  close: string;
  confirmnewpassword: string;
  confirmpassword: string;
  confirmtodelete: string;
  contactinfo: string;
  createaccount: string;
  createcopy: string;
  createddate: string;
  deactivated: string;
  delete: string;
  deletesuccessfully: string;
  description: string;
  detail: string;
  display: string;
  displayorder: string;
  doesnotmatch: string;
  edit: string;
  existed: string;
  fullcontent: string;
  image: string;
  isrole: string;
  keyword: string;
  loginsuccessfully: string;
  login: string;
  loginname: string;
  logout: string;
  lostyourpassword: string;
  manageimages: string;
  maximumlengthoffilenameis: string;
  message: string;
  modifieddate: string;
  name: string;
  newestposts: string;
  newpassword: string;
  newtosite: string;
  no: string;
  or: string;
  otherposts: string;
  otherprojects: string;
  page: string;
  parent: string;
  password: string;
  phone: string;
  posts: string;
  projects: string;
  right: string;
  save: string;
  saveclose: string;
  savecontinue: string;
  savesuccessfully: string;
  search: string;
  seemore: string;
  send: string;
  sendsuccessfully: string;
  somethingwentwrongwhensendingpleasetryagainlater: string;
  status: string;
  submit: string;
  take: string;
  template: string;
  title: string;
  type: string;
  update: string;
  uploadfilesuccessfully: string;
  username: string;
  views: string;
  viewpage: string;
  websitemanagement: string;
  wrongpassword: string;
  youraccountdoesnotexist: string;
  youraccountisblocked: string;
  yourfileisexistedpressoktoreplacetheoldfileorcancelthenrenamethisfile: string;
  yourloginnameorpasswordisincorrect: string;
}
