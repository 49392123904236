import { ContentTranslationModel } from './contenttranslation.model';
import { LanguageModel } from './language.model';

export class ContentModel {
  id: number;
  name: string;
  title: string;
  url: string;
  viewCount: number;
  briefContent: string;
  fullContent: string;
  metaKeyword: string;
  metaDescription: string;
  displayOrder: number;
  take: number;
  statusId: number;
  targetId: number;
  templateId: number;
  createdBy: number;
  createdDate: Date;

  listTranslation: ContentTranslationModel[];

  idname: string;
  createdByNavigation_Name: string;

  constructor(languages: LanguageModel[]) {
    let that = this;

    that.id = 0;
    that.name = '';
    that.title = '';
    that.url = '';
    that.viewCount = 0;
    that.briefContent = '';
    that.fullContent = '';
    that.metaKeyword = '';
    that.metaDescription = '';
    that.displayOrder = 0;
    that.take = 0;
    that.statusId = 3;
    that.targetId = 1;
    that.templateId = 0;
    that.createdBy = 0;
    that.createdDate = new Date();

    that.listTranslation = [];
    languages.forEach(lang => that.listTranslation.push(new ContentTranslationModel(lang.isoCode)));

    that.idname = '';
    that.createdByNavigation_Name = '';
  }

  changeListContentLanguage(list: ContentModel[], iso_code: string): ContentModel[] {
    if (list && list.length > 0) {
      list.forEach(content => {
        if (content.listTranslation && content.listTranslation.length > 0) {
          let lt = content.listTranslation.find(x => x.lang == iso_code);
          if (lt) {
            content.name = lt.name;
            content.title = lt.title;
            content.url = lt.url;
            content.briefContent = lt.briefContent;
            content.fullContent = lt.fullContent;
            content.metaKeyword = lt.metaKeyword;
            content.metaDescription = lt.metaDescription;
          }
        }
      });
    }
    return list;
  }
}
