import { Component, OnInit } from '@angular/core';
import { ClientLayoutComponent } from 'src/app/shared/client/layout/layout.component';
import { ContentCategoryModel } from 'src/app/model/contentcategory.model';

@Component({
  selector: 'client-newsdetail',
  templateUrl: './newsdetail.component.html'
})
export class ClientNewsDetailComponent extends ClientLayoutComponent implements OnInit {
  allcategories: ContentCategoryModel[];
  receiveCategories = ($event: ContentCategoryModel[]) => this.allcategories = $event;

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.allcategories = [];
  }
}
