import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { JsonResultModel } from '../model/jsonresult.model';
import { SearchParamsModel } from '../model/searchparams.model';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private http: HttpClient) { }

  get<TEntity>(area: string, controller: string, id: number = 0, template: string = ''): Observable<TEntity> {
    let url = `/api/${area}/${controller}/${template}`;
    if (id > 0) url += '/' + id;
    return this.http.get<TEntity>(url);
  }

  getPage(area: string, controller: string, params: SearchParamsModel[]): Observable<any> {
    let queryString = '';
    params.forEach(k => queryString += `${k.Key}=${k.Value}&`);
    return this.http.get<any>(`api/${area}/${controller}/GetPage?${queryString}`);
  }

  countExist(area: string, controller: string, params: SearchParamsModel[]): Observable<any> {
    // data.data: number
    let queryString = '';
    params.forEach(k => queryString += `${k.Key}=${k.Value}&`);
    return this.http.get<any>(`api/${area}/${controller}/CountExist?${queryString}`);
  }

  changeStatus(area: string, controller: string, id: number, statusId: number, iduser: number): Observable<JsonResultModel> {
    return this.http.put<JsonResultModel>(`/api/${area}/${controller}/ChangeStatus?id=${id}&statusId=${statusId}&iduser=${iduser}`, {});
  }

  delete(area: string, controller: string, message: string, id: number, iduser: number = 0): Observable<any> {
    if (confirm(message))
      return this.http.delete(`/api/${area}/${controller}?id=${id}&iduser=${iduser}`);
    return new Observable<any>();
  }

  save(area: string, controller: string, model: any, iduser: number = 0, template: string = ''): Observable<JsonResultModel> {
    let query = iduser > 0 ? `?iduser=${iduser}` : '';
    query = template + query;
    return this.post<JsonResultModel>(area, controller, model, query);
  }

  post<T>(area: string, controller: string, model: any, templateandquery: string): Observable<T> {
    return this.http.post<T>(`/api/${area}/${controller}/${templateandquery}`, model);
  }
}
