import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { FeedbackModel } from 'src/app/model/feedback.model';

@Component({
  selector: 'admin-feedback-edit',
  templateUrl: './feedbackedit.component.html'
})
export class AdminFeedbackEditComponent extends AdminLayoutComponent implements OnInit {
  feedback: FeedbackModel;
  confirmpassword: string = '';

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.controller = 'feedback';
    that.backUrl = (that.currentPermissionFamily && that.currentPermissionFamily.length > 0) ? that.currentPermissionFamily[0].url : '/admin';
    that.feedback = new FeedbackModel();

    that.activatedRoute.queryParams.subscribe(params => {
      let id = params['id'] ? params['id'] : 0;
      if (id > 0)
        that.apiService.get<FeedbackModel>(that.area, that.controller, id).subscribe(feedback => {
          feedback != null ? that.feedback = feedback : super.back();
        }, error => that.router.navigate(['/admin']));
      else
        super.back();
    });
  }

  //Validation below
  private isValidName(): boolean {
    return this.feedback.name.trim() != '';
  }
  private message(): string {
    if (!this.isValidName())
      return this.rss.name + ' ' + this.rss.cantbeblank;
    return '';
  }
}
