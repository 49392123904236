import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LoginModel } from '../model/login.model';
import { JsonResultModel } from '../model/jsonresult.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) { }

  login(login: LoginModel): Observable<JsonResultModel> {
    return this.http.post<JsonResultModel>('/api/login', login);
  }
}
