export enum Template {
  Index = 1,
  About = 2,
  Contact = 3,
  Overview = 4,
  OverviewDetail = 5,
  News = 6,
  NewsDetail = 7,
  Project = 8,
  ProjectDetail = 9,
  Video = 10,
  Document = 11,

  AdminAdministrationMenu = 12,
  AdminAdministrationMenuInfo = 13,
  AdminMasterData = 14,
  AdminPermission = 15,
  AdminPermissionInfo = 16,
  AdminUser = 17,
  AdminUserEditAuthorize = 18,
  AdminUserEditPassword = 19,
  AdminUserInfo = 20,

  AdminContent = 21,
  AdminContentInfo = 22,
  AdminContentEditImage = 23,
  AdminContentEditCategory = 24,
  AdminFeedback = 25,
  AdminFeedbackEdit = 26,
  AdminHome = 27,
  AdminLink = 28,
  AdminLinkInfo = 29,
  AdminTag = 30,
  AdminTagInfo = 31
}
