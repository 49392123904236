import { Component, OnInit } from '@angular/core';

import { Permission } from 'src/app/enum/permission.enum';
import { AdminLayoutComponent } from '../layout/layout.component';
import { SessionStorage } from 'src/app/others/sessionstorage';
import { Status } from 'src/app/enum/status.enum';

import { PermissionModel } from 'src/app/model/permission.model';

@Component({
  selector: 'shared-admin-navigation',
  templateUrl: './navigation.component.html'
})
export class AdminNavigationComponent extends AdminLayoutComponent implements OnInit {
  admins: PermissionModel[];
  personals: PermissionModel[];

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.userService.currentUser$.subscribe(user => {
      that.admins = user.permissions.filter(x => x.permissionBit == Permission.Admin && x.parentId == null && x.statusId == Status.Activated);
      that.personals = user.permissions.filter(x => x.permissionBit == Permission.Personal && x.statusId == Status.Activated);
    });
    
    let isocode = that.languageService.getCurrentLanguageCode();
    let sss = new SessionStorage();
    that.languages = sss.getItem(sss.languages);
    that.currentLanguage = that.languages.find(x => x.isoCode == isocode);
  }
}
