import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Resource } from '../resource/resource';
import { SelectModel } from '../model/select.model';
import { ContentModel } from '../model/content.model';

@Injectable({
  providedIn: 'root',
})
export class SubjectService {
  finishLoadPage$ = new Subject<boolean>();
  reloadgooglerecaptcha$ = new Subject<boolean>();
  selects$ = new Subject<SelectModel[]>();

  rss$ = new Subject<Resource>();
  currentLanguageCode$ = new Subject<string>();
}
