import { Component, OnInit } from '@angular/core';
import { ClientLayoutComponent } from 'src/app/shared/client/layout/layout.component';

@Component({
  selector: 'client-home',
  templateUrl: './home.component.html'
})
export class ClientHomeComponent extends ClientLayoutComponent implements OnInit {
  loadedbanner = false;
  loadednewestposts = false;
  loadedvideoabout = false;
  loadedanalysis = false;
  loadedallprojects = false;

  ngOnInit() {
    super.ngOnInit();
    this.baseProgress.start();
    this.loadFirstChild = true;
  }

  receiveBannerEvent($event: boolean) {
    this.baseProgress.inc(20);
    this.loadedbanner = $event;
    this.changeDetectorRef.detectChanges();
  }
  receiveNewestpostsEvent($event: boolean) {
    this.baseProgress.inc(20);
    this.loadednewestposts = $event;
    this.changeDetectorRef.detectChanges();
  }
  receiveVideoaboutEvent($event: boolean) {
    this.baseProgress.inc(20);
    this.loadedvideoabout = $event;
    this.changeDetectorRef.detectChanges();
  }
  receiveAnalysisEvent($event: boolean) {
    this.baseProgress.inc(20);
    this.loadedanalysis = $event;
    this.changeDetectorRef.detectChanges();
  }
  receiveAllprojectsEvent($event: boolean) {
    this.baseProgress.complete();
    this.loadedallprojects = $event;
    this.subjectService.finishLoadPage$.next(true);
  }
}
