import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PermissionModel } from '../model/permission.model';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  currentPermission$ = new Subject<PermissionModel>();
  constructor(private http: HttpClient) { }
  
  changeCurrentPermission(currentPermission: PermissionModel) {
    this.currentPermission$.next(currentPermission);
  }
  
  getPermission(permissions: PermissionModel[]) {
    return this.http.post('/api/admin/permission/GetPermission', permissions);
  }
}
