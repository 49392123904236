import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';

@Component({
  selector: 'admin-feedback',
  templateUrl: './feedback.component.html',
})
export class AdminFeedbackComponent extends AdminLayoutComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.subjectService.currentLanguageCode$.subscribe(newLang => that.changePermissionLanguage(that.currentPermission, newLang));
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.controller = 'feedback';
    that.activatedRoute.queryParams.subscribe(params => that.getPage());
  }
}
