export class ContentTranslationModel {
  idcontent: number;
  lang: string;
  name: string;
  title: string;
  url: string;
  briefContent: string;
  fullContent: string;
  metaKeyword: string;
  metaDescription: string;

  constructor(isoCode: string) {
    this.idcontent = 0;
    this.lang = isoCode;
    this.name = '';
    this.title = '';
    this.url = '';
    this.briefContent = '';
    this.fullContent = '';
    this.metaKeyword = '';
    this.metaDescription = '';
  }
}
