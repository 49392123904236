import { Component, OnInit } from '@angular/core';
import { ClientLayoutComponent } from 'src/app/shared/client/layout/layout.component';
import { FeedbackModel } from 'src/app/model/feedback.model';
import { JsonResultModel } from 'src/app/model/jsonresult.model';
import { LinkModel } from 'src/app/model/link.model';
import { SessionStorage } from 'src/app/others/sessionstorage';
import { Link } from 'src/app/enum/link.enum';
import { ContentModel } from 'src/app/model/content.model';

@Component({
  selector: 'client-contact',
  templateUrl: './contact.component.html'
})
export class ClientContactComponent extends ClientLayoutComponent implements OnInit {
  linkContacts: LinkModel[];
  linkSocialNetworks: LinkModel[];
  feedback: FeedbackModel;
  isAuthenticated = false;
  response = '';
  receiveAuthenticated = ($event: boolean) => this.isAuthenticated = $event;
  receiveResponse = ($event: string) => this.response = $event;

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.linkContacts = [];
    that.linkSocialNetworks = [];
    that.feedback = new FeedbackModel();

    that.apiService.get<LinkModel[]>(that.area, 'link', 0, `GetByLinkID/${Link.Contact}`).subscribe(data => that.linkContacts = data);

    let sss = new SessionStorage();
    let sssLinks = sss.getItem(sss.clientLinks);
    if (sssLinks != '') that.linkSocialNetworks = sssLinks.filter(x => x.linkId == Link.SocialNetwork);

    that.subjectService.currentLanguageCode$.subscribe(lang => new LinkModel([]).changeListLinkLanguage(that.linkContacts, lang));
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }

  private sendFeedback() {
    let that = this;
    if (that.isValidateData()) {
      that.apiService.post<JsonResultModel>(that.area, 'feedback', that.feedback, `?recaptcha=${that.response}`).subscribe(
        data => {
          if (data.value === 0) that.toastrService.error(this.rss.captchaisnotauthenticated);
          else {
            that.feedback = new FeedbackModel();
            that.toastrService.success(that.rss.sendsuccessfully);
          }
        },
        error => that.toastrService.warning(that.rss.somethingwentwrongwhensendingpleasetryagainlater)
      );
      that.subjectService.reloadgooglerecaptcha$.next(true);
    }
  }

  private isValidateData(): boolean {
    let elm = '';
    if (this.feedback.name.trim() == '') elm = this.rss.name;
    else if (this.feedback.phone.trim() == '' && this.feedback.email.trim() == '') elm = `${this.rss.phone} ${this.rss.or} Email`;

    if (elm != '') {
      this.toastrService.warning(`${elm} ${this.rss.cantbeblank}`);
      return false;
    }

    this.subjectService.reloadgooglerecaptcha$.next(false);
    if (!this.isAuthenticated) {
      this.toastrService.warning(this.rss.captchaisnotauthenticated);
      return false;
    }

    return true;
  }
}
