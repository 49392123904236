import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { PermissionModel } from 'src/app/model/permission.model';

@Component({
  selector: 'admin-permission',
  templateUrl: './permission.component.html',
})
export class AdminPermissionComponent extends AdminLayoutComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.controller = 'permission';
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionChildren, newLang);
      that.changeListPermissionLanguage(that.currentUser.permissions, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.userService.currentUser$.subscribe(user => {
      that.permissionService.getPermission(user.permissions).subscribe(data => user.permissions = data as PermissionModel[], error => window.location.href = '/admin')
    });
  }

  changestatusPermission(p: PermissionModel) {
    let that = this;
    that.changestatus(p).subscribe(
      data => {
        p.statusId = data.value;
        that.toastrService.success(that.rss.changestatussuccessfully);
        that.userService.changeCurrentUser(that.currentUser);
      },
      error => {
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      }
    );
  }

  deletePermission(p: PermissionModel) {
    let that = this;
    that.delete(p.id).subscribe(
      () => {
        let index = that.currentUser.permissions.indexOf(p);
        that.currentUser.permissions.splice(index, 1);

        that.userService.changeCurrentUser(that.currentUser);
        that.toastrService.success(that.rss.deletesuccessfully);
      },
      error => {
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      }
    );
  }
}
