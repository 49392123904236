import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { StatusClass } from 'src/app/enum/statusclass.enum';
import { PermissionModel } from 'src/app/model/permission.model';
import { UserPermissionModel } from 'src/app/model/userpermission.model';

@Component({
  selector: 'admin-user-editauthorize',
  templateUrl: './usereditauthorize.component.html'
})
export class AdminUserEditAuthorizeComponent extends AdminLayoutComponent implements OnInit {
  id: number = 0;
  permissions: PermissionModel[] = [];
  userpermissions: UserPermissionModel[] = [];

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.activatedRoute.queryParams.subscribe(params => {
      that.id = params['id'] ? params['id'] : 0;
      if (that.id <= 0)
        that.nglocation.back();
      that.permissions = that.currentUser.permissions.filter(x => x.permissionBit > 4);
      that.userService.getUserPermission(that.id).subscribe(data => that.userpermissions = data);
    });
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changePermissionLanguage(that.currentPermissionParent, newLang);
      that.changeListPermissionLanguage(that.permissions, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }

  changeuserauthorize() {
    let that = this;
    that.userService.changeUserPermission(that.id, that.userpermissions).subscribe(
      data => {
        if (data.length > 0) {
          that.toastrService.success(that.rss.savesuccessfully);
          that.userpermissions = data;
        }
        else
          that.toastrService.warning(StatusClass.warning);
      },
      error => {
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      }
    );
  }

  private changeUserPermission(p: PermissionModel, role: number) {
    let that = this;
    let up: UserPermissionModel = that.userpermissions.find(x => x.idpermission == p.id);
    if (up)
      up.permissionBit = (up && (up.permissionBit & p.permissionBit & role) > 0) ?
        up.permissionBit - role : up.permissionBit + role;
    else
      that.userpermissions.push({ iduser: 0, idpermission: p.id, permissionBit: role });
  }
  private checkPermissionHasRole(p: PermissionModel, role: number): boolean {
    return (p.permissionBit & role) > 0;
  }
  private checkUserHasRole(p: PermissionModel, role: number): boolean {
    let up: UserPermissionModel = this.userpermissions.find(x => x.idpermission == p.id);
    return up && (up.permissionBit & p.permissionBit & role) > 0;
  }
}
