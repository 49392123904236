export class PermissionTranslationModel {
  idpermission: number;
  lang: string;
  name: string;
  description: string;

  constructor(isoCode: string) {
    this.idpermission = 0;
    this.lang = isoCode;
    this.name = '';
    this.description = '';
  }
}
