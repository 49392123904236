import { PermissionModel } from './permission.model';

export class UserModel {
  id: number;
  login: string;
  password: string;
  name: string;
  phone: string;
  email: string;
  address: string;
  iDGeo: number;
  avatar: string;
  statusId: number;
  createdDate: Date;
  modifiedDate: Date;

  select: boolean;
  selectAll: boolean;
  insert: boolean;
  update: boolean;
  updateAll: boolean;
  delete: boolean;
  deleteAll: boolean;

  permissions: PermissionModel[];

  constructor() {
    let that = this;

    that.id = 0;
    that.login = '';
    that.password = '';
    that.name = '';
    that.phone = '';
    that.email = '';
    that.address = '';
    that.iDGeo = 0;
    that.avatar = '';
    that.statusId = 0;
    that.createdDate = new Date();
    that.modifiedDate = new Date();

    that.select = false;
    that.selectAll = false;
    that.insert = false;
    that.update = false;
    that.updateAll = false;
    that.delete = false;
    that.deleteAll = false;

    that.permissions = [];
  }
}
