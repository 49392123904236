import { OnInit } from '@angular/core';
import { Resource } from './resource';

export class Vietnamese extends Resource implements OnInit {
  constructor() { super(); this.setData(); }

  ngOnInit() { this.setData(); }

  private setData() {
    this.activated = 'Kích Hoạt';
    this.add = 'Thêm';
    this.address = 'Địa Chỉ';
    this.all = 'Tất Cả';
    this.alreadyamember = 'Đã Có Tài Khoản';
    this.authorize = 'Phân Quyền';
    this.avatar = 'Ảnh Đại Diện';
    this.back = 'Quay lại';
    this.backtolist = 'Quay Về Danh Sách';
    this.backtotop = 'Về Đầu Trang';
    this.belongto = 'Thuộc Về';
    this.briefcontent = 'Nội Dung Tóm Tắt';
    this.by = 'Bởi';
    this.cantbeblank = 'Không Được Là Khoảng Trống';
    this.captchaisnotauthenticated = 'Captcha Chưa Được Chứng Thực';
    this.categories = 'Danh Mục';
    this.changepassword = 'Đổi Mật Khẩu';
    this.changestatussuccessfully = 'Đổi Trạng Thái Thành Công';
    this.checktheconsolelog = 'Kiểm Tra Console.Log';
    this.close = 'Đóng';
    this.confirmnewpassword = 'Xác Nhận Mật Khẩu Mới';
    this.confirmpassword = 'Xác Nhận Mật Khẩu';
    this.confirmtodelete = 'Xác Nhận Xóa?';
    this.contactinfo = 'Thông Tin Liên Hệ';
    this.createaccount = 'Tạo Tài Khoản';
    this.createcopy = 'Tạo Bản Sao';
    this.createddate = 'Ngày Tạo';
    this.deactivated = 'Khóa';
    this.delete = 'Xóa';
    this.deletesuccessfully = 'Xóa Thành Công';
    this.description = 'Mô Tả';
    this.detail = 'Chi Tiết';
    this.display = 'Hiển Thị';
    this.displayorder = 'Thứ Tự';
    this.doesnotmatch = 'Không Khớp';
    this.edit = 'Sửa';
    this.existed = 'Đã Tồn Tại';
    this.fullcontent = 'Nội Dung Đầy Đủ';
    this.image = 'Ảnh';
    this.isrole = 'Là Vai Trò';
    this.keyword = 'Từ Khóa';
    this.login = 'Đăng Nhập';
    this.loginname = 'Tên Đăng Nhập';
    this.loginsuccessfully = 'Đăng Nhập Thành Công';
    this.logout = 'Đăng Xuất';
    this.lostyourpassword = 'Quên Mật Khẩu';
    this.manageimages = 'Quản Lý Ảnh';
    this.maximumlengthoffilenameis = 'Độ Dài Tối Đa Của Tên Tập Tin Là';
    this.message = 'Tin Nhắn';
    this.modifieddate = 'Ngày Sửa';
    this.name = 'Tên';
    this.newestposts = 'Bài Viết Mới Nhất';
    this.newpassword = 'Mật Khẩu Mới';
    this.newtosite = 'Đăng Ký';
    this.no = 'STT';
    this.or = 'Hoặc';
    this.otherposts = 'Bài Viết Khác';
    this.otherprojects = 'Dự Án Khác';
    this.page = 'Trang';
    this.parent = 'Thuộc';
    this.password = 'Mật Khẩu';
    this.phone = 'Điện Thoại';
    this.posts = 'Bài Viết';
    this.projects = 'Dự Án';
    this.right = 'Quyền';
    this.save = 'Lưu';
    this.saveclose = 'Lưu & Đóng';
    this.savecontinue = 'Lưu & Tiếp Tục';
    this.savesuccessfully = 'Lưu Thành Công';
    this.search = 'Tìm Kiếm';
    this.seemore = 'Xem Thêm';
    this.send = 'Gửi';
    this.sendsuccessfully = 'Gửi Thành Công';
    this.somethingwentwrongwhensendingpleasetryagainlater = 'Có Lỗi Khi Gửi. Vui Lòng Thử Lại Sau';
    this.status = 'Trạng Thái';
    this.submit = 'Gửi';
    this.take = 'Lấy';
    this.template = 'Giao Diện';
    this.title = 'Tiêu Đề';
    this.type = 'Loại';
    this.update = 'Cập Nhật';
    this.uploadfilesuccessfully = 'Tải Tập Tin Lên Thành Công';
    this.username = 'Tên Đăng Nhập';
    this.views = 'Lượt Xem';
    this.viewpage = 'Xem Trang';
    this.websitemanagement = 'Quản Trị Website';
    this.wrongpassword = 'Sai Mật Khẩu';
    this.youraccountdoesnotexist = 'Tài Khoản Không Tồn Tại';
    this.youraccountisblocked = 'Tài Khoản Bị Khóa';
    this.yourfileisexistedpressoktoreplacetheoldfileorcancelthenrenamethisfile = 'Tập Tin Của Bạn Đã Tồn Tại. Chọn OK Để Chèp Đè Lên Tập Tin Cũ. Hoặc Cancel Để Đổi Tên Tập Tin Hiện Tại';
    this.yourloginnameorpasswordisincorrect = 'Tên Đăng Nhập Hoặc Mật Khẩu Không Đúng';
  }
}
