import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Link } from 'src/app/enum/link.enum';
import { Resource } from 'src/app/resource/resource';
import { SessionStorage } from 'src/app/others/sessionstorage';

import { ContentModel } from 'src/app/model/content.model';
import { LinkModel } from 'src/app/model/link.model';

import { ApiService } from 'src/app/service/api.service';
import { SubjectService } from 'src/app/service/subject.service';

@Component({
  selector: 'custom-client-videoabout',
  templateUrl: './videoabout.component.html'
})
export class CustomClientVideoAboutComponent implements OnInit {
  @Input('rss') rss: Resource;
  @Output() videoaboutEvent = new EventEmitter<boolean>();

  about: ContentModel;
  youtube: LinkModel;
  youtubethumbnail: string;
  youtubeurl: string;

  constructor(private apiService: ApiService, private subjectService: SubjectService) { }

  ngOnInit() {
    let that = this;
    let sss = new SessionStorage();
    that.about = new ContentModel([]);
    that.youtube = new LinkModel([]);
    that.youtubethumbnail = '';
    that.youtubeurl = '';

    that.apiService.get<ContentModel>('client', 'content', 0, 'GetAbout').subscribe(about => {
      that.about = about;
      sss.setClientContents([about]);
    });
    that.apiService.get<LinkModel[]>('client', 'link', 0, `GetByLinkID/${Link.Youtube}`).subscribe(data => {
      let youtube = data[0];
      if (youtube) {
        that.youtube = youtube;
        that.youtubethumbnail = youtube.image != youtube.url ? youtube.image : `https://img.youtube.com/vi/${youtube.url}/default.jpg`;
        that.youtubeurl = 'https://www.youtube.com/watch?v=' + youtube.url;
        sss.setClientLinks([youtube]);
      }
    });
    
    that.subjectService.currentLanguageCode$.subscribe(newLang => new ContentModel([]).changeListContentLanguage([that.about], newLang));
    that.subjectService.rss$.subscribe(rss => that.rss = rss);

    that.videoaboutEvent.emit(true);
  }
}
