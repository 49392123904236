import { Component, Input, OnInit } from '@angular/core';
import { LinkModel } from 'src/app/model/link.model';
import { SessionStorage } from 'src/app/others/sessionstorage';
import { ContentLinkCustomModel } from 'src/app/model/contentlinkcustom.model';
import { Link } from 'src/app/enum/link.enum';

@Component({
  selector: 'ngx-slick-carousel-listthumb-for-slidernav',
  template: `<ng-container *ngIf="images.length > 0">
              <ngx-slick-carousel class="carousel list-thumb" #slickModal="slick-carousel" [config]="listthumboptions">
                <div class="slide item lk-innershadow" ngxSlickItem *ngFor="let img of images" [lazyLoad]="img" [defaultImage]="img"></div>
              </ngx-slick-carousel>
             </ng-container>`
})
export class NgxSlickCarouselListthumbForSlidernavComponent implements OnInit {
  @Input('idcontent') idcontent = 0;
  images: string[];
  listthumboptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    infinite: false,
    autoplay: false,
    asNavFor: '.slider-nav'
  }

  ngOnInit() {
    let that = this;
    that.images = [];

    let sss = new SessionStorage();
    let links: LinkModel[] = sss.getItem(sss.clientLinks);
    let cls: ContentLinkCustomModel[] = sss.getItem(sss.clientContentLinks);
    if (cls) {
      let cl = cls.find(x => x.idcontent == that.idcontent);
      if (cl) that.images = links.filter(x => cl.idlinks.includes(x.id) && x.linkId == Link.ImageDetail).map(x => x.image);
    }
  }
}
