import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { SelectModel } from 'src/app/model/select.model';
import { JsonResultModel } from 'src/app/model/jsonresult.model';

@Component({
  selector: 'admin-content-edit-image',
  templateUrl: './contenteditimage.component.html'
})
export class AdminContentEditImageComponent extends AdminLayoutComponent implements OnInit {
  id: number;
  images: SelectModel[] = [];
  receiveLink = ($event: SelectModel[]) => this.images = $event;

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.controller = 'content';
    that.id = +that.activatedRoute.snapshot.queryParams['id'];
    that.backUrl = that.currentPermissionFamily && that.currentPermissionFamily.length > 0 ? that.currentPermissionFamily[0].url : '/admin';
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }

  removeImage(img: SelectModel) {
    let that = this;
    if (confirm(that.rss.confirmtodelete)) {
      let old = that.images.find(x => x.id == img.id);
      if (old) {
        let index = that.images.indexOf(old);
        old.selected = !old.selected;
        that.images[index] = old;
      }
      that.subjectService.selects$.next(that.images);
    }
  }

  saveImage() {
    let that = this;
    let images = that.images.filter(x => x.selected).map(x => x.id);
    that.apiService.post<JsonResultModel>(that.area, that.controller, images, `PostLinks?idcontent=${that.id}`).subscribe(
      data => that.toastrService.success(that.rss.savesuccessfully),
      error => {
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      }
    );
  }
}
