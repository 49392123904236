import { Component, OnInit, Input } from '@angular/core';
import { SubjectService } from 'src/app/service/subject.service';
import { SimpleTagModel } from 'src/app/model/simpletag.model';
import { ApiService } from 'src/app/service/api.service';
import { SessionStorage } from 'src/app/others/sessionstorage';
import { LinkModel } from 'src/app/model/link.model';
import { ContentLinkCustomModel } from 'src/app/model/contentlinkcustom.model';

@Component({
  selector: 'custom-client-sharetag',
  templateUrl: './sharetag.component.html'
})
export class CustomClientShareTagComponent implements OnInit {
  @Input('idcontent') idcontent = 0; // Do not pass image and listtag if idcontent > 0
  @Input('url') url = '';
  @Input('title') title = '';
  @Input('metadescription') metadescription = '';
  @Input('image') image = '';
  @Input('listtag') listtag: SimpleTagModel[] = [];

  tagnames = '';

  constructor(private apiService: ApiService, private subjectService: SubjectService) { }

  ngOnInit() {
    let that = this;
    if (that.idcontent > 0) {
      that.apiService.get<SimpleTagModel[]>('client', 'tag', that.idcontent, 'GetByIDContent').subscribe(tags => {
        that.listtag = tags;
        that.tagnames = this.listtag.map(x => x.tag).join(',');
      });

      let sss = new SessionStorage();
      let links: LinkModel[] = sss.getItem(sss.clientLinks);
      let cls: ContentLinkCustomModel[] = sss.getItem(sss.clientContentLinks);
      if (cls) {
        let cl = cls.find(x => x.idcontent == that.idcontent);
        if (cl) {
          let img = links.find(x => cl.idlinks.includes(x.id));
          if (img) that.image = img.image;
        }
      }
    }

    that.subjectService.currentLanguageCode$.subscribe(lang => {
      new SimpleTagModel([]).changeListSimpleTagLanguage(that.listtag, lang);
    });
  }

  private geturl(): string {
    return `${window.location.protocol}//${window.location.host}/${this.url}`;
  }
}
