import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { SelectModel } from 'src/app/model/select.model';

@Component({
  selector: 'ng-multiselect-dropdown-content-parent',
  template: `<ng-multiselect-dropdown [data]="parentids"
                                      [(ngModel)]="parentid"
                                      [settings]="parentidSettings"
                                      (onSelect)="changeparentid($event)"
                                      (onDeSelect)="changeparentid($event)">
             </ng-multiselect-dropdown>`
})
export class NgMultiselectDropdownContentParentComponent implements OnInit {
  @Input() id: number;
  @Input('area') area: string;
  @Input('controller') controller: string;
  @Output() parentEvent = new EventEmitter<SelectModel[]>();

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    let that = this;
    that.apiService.get<SelectModel[]>(that.area, that.controller, that.id, 'GetParents').subscribe(
      parents => {
        that.parentids = parents;
        that.parentid = parents.filter(x => x.selected);
        that.loadparentid();
        that.parentEvent.emit(that.parentids);
      }
    );
  }

  parentids: SelectModel[];
  parentid: SelectModel[];
  parentidSettings: any = {};
  private changeparentid(item: any) {
    let old = this.parentids.find(x => x.id == item.id);
    if (old) {
      let index = this.parentids.indexOf(old);
      old.selected = !old.selected;
      this.parentids[index] = old;
      this.parentEvent.emit(this.parentids);
    }
  }
  private loadparentid() {
    this.parentidSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name'
    };
  }
}
