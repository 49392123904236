import { ContentCategoryTranslationModel } from './contentcategorytranslation.model';
import { LanguageModel } from './language.model';

export class ContentCategoryModel {
  id: number;
  idcontent: number;
  name: string;
  url: string;
  fullContent: string;
  displayOrder: number;
  statusId: number;
  createdBy: number;
  createdDate: Date;
  listTranslation: ContentCategoryTranslationModel[];

  constructor(idcontent: number, languages: LanguageModel[]) {
    let that = this;

    that.id = 0;
    that.idcontent = idcontent;
    that.name = '';
    that.url = '';
    that.fullContent = '';
    that.displayOrder = 0;
    that.statusId = 3;
    that.createdBy = 0;
    that.createdDate = new Date();

    that.listTranslation = [];
    languages.forEach(language => that.listTranslation.push(new ContentCategoryTranslationModel(language.isoCode)));
  }

  changeListContentCategoryLanguage(list: ContentCategoryModel[], iso_code: string): ContentCategoryModel[] {
    list.forEach(model => {
      if (model.listTranslation && model.listTranslation.length > 0) {
        let lt = model.listTranslation.find(x => x.lang == iso_code);
        if (lt) {
          model.name = lt.name;
          model.url = lt.url;
          model.fullContent = lt.fullContent;
        }
      }
    });
    return list;
  }
}
