export class LinkTranslationModel {
  lang: string;
  name: string;
  detail: string;

  constructor(isoCode: string) {
    this.lang = isoCode;
    this.name = '';
    this.detail = '';
  }
}
