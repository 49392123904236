import { ContentModel } from './content.model';
import { LinkModel } from './link.model';
import { SimpleContentModel } from './simplecontent.model';
import { LanguageModel } from './language.model';

export class ContentAllProjectsModel {
  project: ContentModel;
  links: LinkModel[];
  projects: SimpleContentModel[];

  constructor(languages: LanguageModel[]) {
    this.project = new ContentModel(languages);
    this.links = [];
    this.projects = [];
  }

  changeContentAllProjectsLanguage(allprojects: ContentAllProjectsModel, iso_code: string): ContentAllProjectsModel {
    allprojects.project = new ContentModel([]).changeListContentLanguage([allprojects.project], iso_code)[0];
    allprojects.links = new LinkModel([]).changeListLinkLanguage(allprojects.links, iso_code);
    allprojects.projects = new SimpleContentModel([]).changeListSimpleContentLanguage(allprojects.projects, iso_code);
    return allprojects;
  }
}
