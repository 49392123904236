import { Component, Input, OnInit } from '@angular/core';
import { LinkModel } from 'src/app/model/link.model';
import { SessionStorage } from 'src/app/others/sessionstorage';
import { ContentLinkCustomModel } from 'src/app/model/contentlinkcustom.model';
import { Link } from 'src/app/enum/link.enum';

@Component({
  selector: 'ngx-slick-carousel-slidernav-for-listthumb',
  template: `<ng-container *ngIf="images.length > 0">
              <div class="slider-control">
                <ngx-slick-carousel class="carousel slider-nav" #slickModal="slick-carousel" [config]="slidernavoptions">
                  <div class="slide item lk-innershadow" style="background-position: center center; background-size: cover;"
                       ngxSlickItem *ngFor="let img of images" [lazyLoad]="img" [defaultImage]="img"></div>
                </ngx-slick-carousel>
              </div>
             </ng-container>`
})
export class NgxSlickCarouselSlidernavForListthumbComponent implements OnInit {
  @Input('idcontent') idcontent = 0;
  images: string[];
  slidernavoptions = {
    slidesToShow: 5,
    slidesToScroll: 5,
    asNavFor: '.list-thumb',
    dots: false,
    arrows: false,
    infinite: false,
    // centerMode: true,
    focusOnSelect: true
    // prevArrow: $('.top-arrow'),
    // nextArrow: $('.bottom-arrow'),
  }

  ngOnInit() {
    let that = this;
    that.images = [];

    let sss = new SessionStorage();
    let links: LinkModel[] = sss.getItem(sss.clientLinks);
    let cls: ContentLinkCustomModel[] = sss.getItem(sss.clientContentLinks);
    if (cls) {
      let cl = cls.find(x => x.idcontent == that.idcontent);
      if (cl) that.images = links.filter(x => cl.idlinks.includes(x.id) && x.linkId == Link.ImageDetail).map(x => x.image);
    }
  }
}
