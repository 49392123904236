import { Component, OnInit } from '@angular/core';
import { HttpRequest, HttpEventType } from '@angular/common/http';

import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { MasterData } from 'src/app/enum/masterdata.enum';
import { Link } from 'src/app/enum/link.enum';
import { LinkModel } from 'src/app/model/link.model';
import { JsonResultModel } from 'src/app/model/jsonresult.model';
import { MasterDataModel } from 'src/app/model/masterdata.model';

@Component({
  selector: 'admin-link-info',
  templateUrl: './linkinfo.component.html'
})
export class AdminLinkInfoComponent extends AdminLayoutComponent implements OnInit {
  link: LinkModel;

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.controller = 'link';
    that.link = new LinkModel(that.languages);
    that.loadlinkid();
    that.loadtargetid();
    that.backUrl = that.currentPermissionFamily && that.currentPermissionFamily.length > 0 ?
      that.currentPermissionFamily[0].url : '/admin';

    that.activatedRoute.queryParams.subscribe(params => {
      let id = params['id'] ? params['id'] : 0;
      if (id > 0) {
        that.apiService.get<LinkModel>(that.area, that.controller, id).subscribe(
          link => {
            that.languages.forEach(language => {
              if (!link.listTranslation.find(x => x.lang == language.isoCode)) {
                link.listTranslation.push({ lang: language.isoCode, name: '', detail: '' });
              }
            });
            that.link = link;
            that.loadlinkid();
            that.loadtargetid();
          },
          error => window.location.href = '/admin'
        );
      }
      else
        that.link = new LinkModel(that.languages);
    });
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }

  linkids: MasterDataModel[];
  linkid: MasterDataModel[];
  linkidSettings: any = {};
  private changelinkid = (item: any) => this.link.linkId = item.value;
  private removelinkid(item: any) {
    this.link.linkId = 0;
  }
  private loadlinkid() {
    let that = this;
    that.linkids = that.masterdata.filter(x => x.id == MasterData.Link && x.lang == that.currentLanguage.isoCode && x.value > 0);
    that.linkid = [that.linkids.find(x => x.value == that.link.linkId)];
    that.linkidSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: 'value',
      textField: 'name'
    };
  }

  targetids: MasterDataModel[];
  targetid: MasterDataModel[];
  targetidSettings: any = {};
  private changetargetid = (item: any) => this.link.targetId = item.value;
  private removetargetid(item: any) {
    let that = this;
    that.targetid = [that.targetids.find(x => x.value == that.link.targetId)];
  }
  private loadtargetid() {
    let that = this;
    that.targetids = that.masterdata.filter(x => x.id == MasterData.Target && x.lang == that.currentLanguage.isoCode && x.value > 0);
    that.targetid = [that.targetids.find(x => x.value == that.link.targetId)];
    that.targetidSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: 'value',
      textField: 'name'
    };
  }

  saveLink(type: string) {
    let that = this;
    that.progressService.change({ loaded: 50, total: 250 });
    that.apiService.post<JsonResultModel>(that.area, that.controller, that.link, "PostLink").subscribe(
      data => {
        that.toastrService.success(that.rss.savesuccessfully);
        that.progressService.change({ loaded: 50, total: 0 });

        that.saveLinkFile(data.value);

        switch (type) {
          case that.rss.savecontinue:
            that.router.navigate([window.location.pathname.split('?')[0]]);
            break;
          case that.rss.saveclose:
            that.router.navigate([that.backUrl]);
            break;
          case that.rss.save:
            that.router.navigate([window.location.pathname], { queryParams: { id: data.value } });
            break;
        }
      },
      error => {
        that.progressService.change({ loaded: 150, total: 0 });
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      }
    );
  }

  saveLinkFile(id: number) {
    let that = this;
    const formData = new FormData();

    for (let file of that.linkFiles)
      formData.append(file.name, file);

    const uploadReq = new HttpRequest('POST', `api/admin/link/UploadFile?id=${id}`, formData, {
      reportProgress: true,
    });

    that.http.request(uploadReq).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        that.progressService.changeWithPercent(Math.round(100 * event.loaded / event.total), 100, that.loadedProgress, that.totalProgress);
      else if (event.type === HttpEventType.Response && event.status == 200) {
        that.toastrService.success(that.rss.uploadfilesuccessfully);
        that.progressService.change({ loaded: 50, total: 0 });
      }
    },
      data => console.log(data));
  }

  private file: any;
  private linkFiles: any;
  private getLinkFiles(files) {
    let that = this;
    if (files.length > 0) {
      let image = '';
      for (let file of files) {
        if (file.name.length > 200) {
          that.toastrService.warning(`${that.rss.maximumlengthoffilenameis} 200`);
          return;
        }
        image = `/media/link/${that.link.id}/${file.name}`;
      }
      that.http.get(image).subscribe(
        () => {
          if (confirm(that.rss.yourfileisexistedpressoktoreplacetheoldfileorcancelthenrenamethisfile)) {
            that.linkFiles = files;
            that.link.image = image;
          }
        },
        (err) => {
          if (err.status == 200) {
            if (confirm(that.rss.yourfileisexistedpressoktoreplacetheoldfileorcancelthenrenamethisfile)) {
              that.linkFiles = files;
              that.link.image = image;
            }
          } else {
            that.linkFiles = files;
            that.link.image = image;
          }
        });
    }
  }

  private getImageName(): string {
    let that = this;
    if (that.link.linkId == Link.SocialNetwork || that.link.linkId == Link.Contact)
      return "Icon";
    return that.rss.image;
  }
  private enableUpload(): boolean {
    let that = this;
    return (that.link.linkId != Link.SocialNetwork && that.link.linkId != Link.Contact && that.link.linkId != Link.Youtube);
  }
}
