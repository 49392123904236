import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { SessionStorage } from 'src/app/others/sessionstorage';
import { Link } from 'src/app/enum/link.enum';
import { LinkModel } from 'src/app/model/link.model';
import { ContentLinkCustomModel } from 'src/app/model/contentlinkcustom.model';
import { SubjectService } from 'src/app/service/subject.service';
import { ApiService } from 'src/app/service/api.service';
import { LanguageService } from 'src/app/service/language.service';
import { ContentModel } from 'src/app/model/content.model';

@Component({
  selector: 'custom-client-banner',
  templateUrl: './banner.component.html'
})
export class CustomClientBannerComponent implements OnInit {
  @Output() bannerEvent = new EventEmitter<boolean>();

  templateid: number = 0;

  images: LinkModel[];
  offset: number = 100;
  optionsWithoutTitle = {
    items: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    autoplay: true,
    loop: true
  };
  optionsWithTitle = {
    items: 1,
    loop: true,
    center: true,
    padding: 0,
    margin: 0,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    callbacks: true,
    responsive: {
      768: {
        nav: true
      }
    }
  };

  constructor(private subjectService: SubjectService, private apiService: ApiService, private languageService: LanguageService) {
    let that = this;
    let sss = new SessionStorage();
    that.images = [];

    let currentContent = sss.getItem(sss.currentContent) as ContentModel;
    that.templateid = currentContent.templateId;
    let links: LinkModel[] = sss.getItem(sss.clientLinks);
    let cls: ContentLinkCustomModel[] = sss.getItem(sss.clientContentLinks);
    if (cls) {
      let cl = cls.find(x => x.idcontent == currentContent.id);
      if (cl) {
        that.images = links.filter(x => cl.idlinks.includes(x.id) && x.linkId == Link.Banner);
        new LinkModel([]).changeListLinkLanguage(that.images, that.languageService.getCurrentLanguageCode());
      }
    }
    
    if (that.images.length == 0)
      that.apiService.get<LinkModel[]>('client', 'link', 0, `GetByIDContent/${currentContent.id}`).subscribe(data => {
        sss.setClientLinks(data);
        sss.setClientContentLinks(currentContent.id, data.map(x => x.id));
        that.images = data.filter(x => x.linkId == Link.Banner);
      });
  }

  ngOnInit() {
    let that = this;
    that.bannerEvent.emit(true);
    that.subjectService.currentLanguageCode$.subscribe(lang => {
      if (that.templateid == 1) new LinkModel([]).changeListLinkLanguage(that.images, lang);
    });
  }
}
