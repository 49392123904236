import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { ContentCategoryModel } from 'src/app/model/contentcategory.model';
import { ContentCategoryTranslationModel } from 'src/app/model/contentcategorytranslation.model';

@Component({
  selector: 'admin-content-edit-category',
  templateUrl: './contenteditcategory.component.html'
})
export class AdminContentEditCategoryComponent extends AdminLayoutComponent implements OnInit {
  id: number;
  currentTabTranslation: string;
  model: ContentCategoryModel;
  private ckeditorConfig = {
    extraPlugins: 'codesnippet',
    contentsCss: [''],
    allowedContent: true
  }

  ngOnInit() {
    let that = this;
    let id = that.activatedRoute.snapshot.queryParams['id'];
    if (!id || +id <= 0) that.back();
    super.ngOnInit();
    that.controller = 'contentcategory';
    that.id = +id;
    that.model = new ContentCategoryModel(that.id, that.languages);
    that.currentTabTranslation = that.currentLanguage.isoCode;

    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
      that.changeListContentLanguage(that.listData, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.activatedRoute.queryParams.subscribe(params => {
      that.queryString = [
        { Key: 'page', Value: that.page },
        { Key: 'pageSize', Value: that.pageSize },
        { Key: 'id', Value: that.id },
        { Key: 'keyword', Value: that.keyword }
      ];
      that.getPage();
    });
  }

  private changeCurrentTabTranslation(isoCode: string) {
    this.currentTabTranslation = isoCode;
  }

  private passValueToModal(category: ContentCategoryModel) {
    let that = this;
    if (category) {
      that.model = category;
      that.languages.forEach(lang => {
        let tran = that.model.listTranslation.find(x => x.lang == lang.isoCode);
        if (!tran) that.model.listTranslation.push(new ContentCategoryTranslationModel(lang.isoCode));
      });
    }
    else
      that.model = new ContentCategoryModel(that.id, that.languages);
  }

  private saveCategory() {
    let that = this;
    let translation = that.model.listTranslation.find(x => x.lang == 'vi');
    if (translation.name.trim() == '') {
      that.toastrService.error(`${that.rss.name} ${that.rss.cantbeblank}`);
      return;
    }

    let id = that.model.id;
    that.save(that.model).subscribe(
      data => {
        if (id > 0) {
          that.model = data.value;
          let old = that.listData.find(x => x.id == that.model.id);
          old = that.model;
          that.languages.forEach(lang => {
            let tran = old.listTranslation.find(x => x.lang == lang.isoCode);
            if (!tran) old.listTranslation.push(new ContentCategoryTranslationModel(lang.isoCode));
          });
        } else that.getPage();
        that.toastrService.success(that.rss.savesuccessfully);
      },
      error => {
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      });
  }
}
