import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { OwlCarousel } from 'ngx-owl-carousel';

import { Resource } from 'src/app/resource/resource';
import { ContentModel } from 'src/app/model/content.model';
import { SimpleContentModel } from 'src/app/model/simplecontent.model';

import { SubjectService } from 'src/app/service/subject.service';
import { ApiService } from 'src/app/service/api.service';
import { SessionStorage } from 'src/app/others/sessionstorage';

@Component({
  selector: 'custom-client-newestposts',
  templateUrl: './newestposts.component.html'
})
export class CustomClientNewestPostsComponent implements OnInit {
  @Input('rss') rss: Resource;
  @Output() newestpostsEvent = new EventEmitter<boolean>();
  @ViewChild('viewowlcarousel') viewowlcarousel: OwlCarousel;

  currentContent: ContentModel;
  newestPosts: SimpleContentModel[];
  owlcarouseloptions = {
    loop: true,
    center: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    callbacks: true,
    responsiveClass: true,
    responsive: {
      '480': {
        items: 1
      },
      '768': {
        items: 3,
        nav: false
      },
      '992': {
        items: 3
      }
    }
  };

  constructor(private subjectService: SubjectService, private apiService: ApiService) {
    let sss = new SessionStorage();
    let that = this;
    that.newestPosts = [];
    
    that.currentContent = sss.getItem(sss.currentContent) as ContentModel;
    that.apiService.get<SimpleContentModel[]>('client', 'content', that.currentContent.take, 'GetNewestPosts').subscribe(posts => that.newestPosts = posts);
  }

  ngOnInit() {
    let that = this;
    that.currentContent = new ContentModel([]); //LKTODO: kiem tra ngon ngu
    that.newestpostsEvent.emit(true);

    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      new ContentModel([]).changeListContentLanguage([that.currentContent], newLang);
      new SimpleContentModel([]).changeListSimpleContentLanguage(that.newestPosts, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }
}
