import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Resource } from 'src/app/resource/resource';
import { SessionStorage } from 'src/app/others/sessionstorage';

import { ContentModel } from 'src/app/model/content.model';
import { ContentAnalysisModel } from 'src/app/model/contentanalysis.model';

import { ApiService } from 'src/app/service/api.service';
import { SubjectService } from 'src/app/service/subject.service';

@Component({
  selector: 'custom-client-analysis',
  templateUrl: './analysis.component.html'
})
export class CustomClientAnalysisComponent implements OnInit {
  @Input('rss') rss: Resource;
  @Output() analysisEvent = new EventEmitter<boolean>();

  analysis: ContentAnalysisModel;
  analysisLoaded = false;

  currentContent: ContentModel;

  constructor(private apiService: ApiService, private subjectService: SubjectService) { }

  ngOnInit() {
    let that = this;
    let sss = new SessionStorage();
    that.analysis = new ContentAnalysisModel();

    that.currentContent = sss.getItem(sss.currentContent);

    let analysis = sss.getItem(sss.clientContentAnalysis);
    if (analysis == '')
      that.apiService.get<ContentAnalysisModel>('client', 'content', 0, 'GetAnalysis').subscribe(data => {
        that.analysis = data;
        that.analysisLoaded = true;
        sss.setItem(sss.clientContentAnalysis, data);
        that.analysisEvent.emit(true);
      });
    else {
      that.analysis = analysis;
      that.analysisLoaded = true;
      that.analysisEvent.emit(true);
    }

    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.subjectService.currentLanguageCode$.subscribe(lang => new ContentModel([]).changeListContentLanguage([that.currentContent], lang));
  }
}
