import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { SearchParamsModel } from 'src/app/model/searchparams.model';
import { UserModel } from 'src/app/model/user.model';

@Component({
  selector: 'admin-user-info',
  templateUrl: './userinfo.component.html'
})
export class AdminUserInfoComponent extends AdminLayoutComponent implements OnInit {
  user: UserModel;
  confirmpassword: string = '';
  isCheckingExist: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.controller = 'user';
    that.backUrl = that.currentPermissionParent ? that.currentPermissionParent.url : '/admin';
    that.user = new UserModel();
    that.activatedRoute.queryParams.subscribe(params => {
      let id = params['id'] ? params['id'] : 0;
      that.apiService.get<UserModel>(that.area, that.controller, id).subscribe(
        user => that.user = user,
        error => window.location.href = '/admin'
      );
    });
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
      that.changePermissionLanguage(that.currentPermissionParent, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }

  //Validation below
  private checkExist() {
    let that = this;
    that.isCheckingExist = true;
    let params: SearchParamsModel[] = [];
    params.push({ Key: 'id', Value: that.user.id });
    params.push({ Key: 'login', Value: that.user.login });
    that.apiService.countExist(that.area, that.controller, params).subscribe(data => that.isCheckingExist = data.data != 0);
  }
  private isValid(): boolean {
    let that = this;
    return !that.isCheckingExist && that.isValidLogin() && that.isValidName() && that.isValidPassword() && that.isValidConfirmPassword();
  }
  private isValidLogin(): boolean {
    return this.user.login.trim() != '';
  }
  private isValidName(): boolean {
    let that = this;
    that.user.name = that.user.name.trim();
    return that.user.name != '';
  }
  private isValidPassword(): boolean {
    let that = this;
    that.user.password = that.user.password.trim();
    return that.user.id > 0 || that.user.password != '';
  }
  private isValidConfirmPassword(): boolean {
    let that = this;
    return that.user.id > 0 || (that.isValidPassword() && that.confirmpassword == that.user.password);
  }
  private message(): string {
    let that = this;
    let messages: string[] = [];
    if (that.isCheckingExist) messages.push(that.rss.loginname + ' ' + that.rss.existed);
    if (!that.isValidLogin()) messages.push(that.rss.loginname + ' ' + that.rss.cantbeblank);
    if (!that.isValidName()) messages.push(that.rss.name + ' ' + that.rss.cantbeblank);
    if (!that.isValidPassword()) messages.push(that.rss.password + ' ' + that.rss.cantbeblank);
    if (!that.isValidConfirmPassword()) messages.push(that.rss.confirmpassword + ' ' + that.rss.doesnotmatch);
    return messages.join(', ');
  }
}
