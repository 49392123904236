import { Component, OnInit, Input } from '@angular/core';
import { SimpleContentModel } from 'src/app/model/simplecontent.model';
import { ApiService } from 'src/app/service/api.service';
import { SubjectService } from 'src/app/service/subject.service';
import { Resource } from 'src/app/resource/resource';
import { ContentModel } from 'src/app/model/content.model';

@Component({
  selector: 'custom-client-othercontents',
  templateUrl: './othercontents.component.html'
})
export class CustomClientOtherContentsComponent implements OnInit {
  @Input('rss') rss: Resource;
  @Input('currentContent') currentContent: ContentModel;

  othercontents: SimpleContentModel[];
  othercontentsoptions = {
    items: 1,
    loop: false,
    center: false,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    callbacks: true,
    responsive: {
      480: {
        items: 1
      },
      768: {
        items: 2
      },
      992: {
        items: 4,
        nav: false
      }
    }
  }

  constructor(private apiService: ApiService, private subjectService: SubjectService) { }

  ngOnInit() {
    let that = this;
    that.othercontents = [];

    let url = `GetOtherContents?idcontent=${that.currentContent.id}&templateid=${that.currentContent.templateId}&take=${that.currentContent.take}`;
    that.apiService.get<SimpleContentModel[]>('client', 'content', 0, url).subscribe(contents => that.othercontents = contents);

    that.subjectService.currentLanguageCode$.subscribe(newLang => new SimpleContentModel([]).changeListSimpleContentLanguage(that.othercontents, newLang));
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }
}
