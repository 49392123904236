import { Resource } from './resource';
import { OnInit } from '@angular/core';

export class English extends Resource implements OnInit {
  constructor() { super(); this.setData(); }

  ngOnInit() { this.setData(); }

  private setData() {
    this.activated = 'Activated';
    this.add = 'Add';
    this.address = 'Address';
    this.all = 'All';
    this.alreadyamember = 'Already A Member';
    this.authorize = 'Authorize';
    this.avatar = 'Avatar';
    this.back = 'Back';
    this.backtolist = 'Back To List';
    this.backtotop = 'Back To Top';
    this.belongto = 'Belong To';
    this.briefcontent = 'Brief Content';
    this.by = 'By';
    this.cantbeblank = 'Cant Be Blank';
    this.captchaisnotauthenticated = 'Captcha Is Not Authenticated';
    this.categories = 'Categories';
    this.changepassword = 'Change Password';
    this.changestatussuccessfully = 'Change Status Successfully';
    this.checktheconsolelog = 'Check The Console.Log';
    this.close = 'Close';
    this.confirmnewpassword = 'Confirm New Password';
    this.confirmpassword = 'Confirm Password';
    this.confirmtodelete = 'Confirm To Delete?';
    this.contactinfo = 'Contact Info';
    this.createaccount = 'Create Account';
    this.createcopy = 'Create Copy';
    this.createddate = 'Created Date';
    this.deactivated = 'Deactivated';
    this.delete = 'Delete';
    this.deletesuccessfully = 'Delete Successfully';
    this.description = 'Description';
    this.detail = 'Detail';
    this.display = 'Display';
    this.displayorder = 'Display Order';
    this.doesnotmatch = 'Does Not Match';
    this.edit = 'Edit';
    this.existed = 'Existed';
    this.fullcontent = 'Full Content';
    this.image = 'Image';
    this.isrole = 'Is Role';
    this.keyword = 'Keyword';
    this.login = 'Login';
    this.loginname = 'Login Name';
    this.loginsuccessfully = 'Login Successfully';
    this.logout = 'Log Out';
    this.lostyourpassword = 'Lost Your Password';
    this.manageimages = 'Manage Images';
    this.maximumlengthoffilenameis = 'Maximum Length Of File Name Is';
    this.message = 'Message';
    this.modifieddate = 'Modified Date';
    this.name = 'Name';
    this.newestposts = 'Newest Posts';
    this.newpassword = 'New Password';
    this.newtosite = 'New To Site';
    this.no = 'No';
    this.or = 'Or';
    this.otherposts = 'Other Posts';
    this.otherprojects = 'Other Projects';
    this.page = 'Page';
    this.parent = 'Parent';
    this.password = 'Password';
    this.phone = 'Phone';
    this.posts = 'Posts';
    this.projects = 'Projects';
    this.right = 'Right';
    this.save = 'Save';
    this.saveclose = 'Save & Close';
    this.savecontinue = 'Save & Continue';
    this.savesuccessfully = 'Save Successfully';
    this.search = 'Search';
    this.seemore = 'See More';
    this.send = 'Send';
    this.sendsuccessfully = 'Send Successfully';
    this.somethingwentwrongwhensendingpleasetryagainlater = 'Something Went Wrong When Sending. Please Try Again Later';
    this.status = 'Status';
    this.submit = 'Submit';
    this.take = 'Take';
    this.template = 'Template';
    this.title = 'Title';
    this.type = 'Type';
    this.update = 'Update';
    this.uploadfilesuccessfully = 'Upload File Successfully';
    this.username = 'Username';
    this.views = 'Views';
    this.viewpage = 'View Page';
    this.websitemanagement = 'Website Management';
    this.wrongpassword = 'Wrong Password';
    this.youraccountdoesnotexist = 'Your Account Does Not Exist';
    this.youraccountisblocked = 'Your Account Is Blocked';
    this.yourfileisexistedpressoktoreplacetheoldfileorcancelthenrenamethisfile = 'Your File Is Existed. Press OK To Replace The Old File. Or Cancel Then Rename This File';
    this.yourloginnameorpasswordisincorrect = 'Your Loginname Or Password Is Incorrect';
  }
}
