import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';

@Component({
  selector: 'admin-user-editpassword',
  templateUrl: './usereditpassword.component.html'
})
export class AdminUserEditPasswordComponent extends AdminLayoutComponent implements OnInit {
  id: number = 0;
  password: string = '';
  newpassword: string = '';
  confirmnewpassword: string = '';

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.activatedRoute.queryParams.subscribe(params => {
      that.id = params['id'] ? params['id'] : 0;
      if (that.id <= 0)
        that.nglocation.back();
    });
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
      that.changePermissionLanguage(that.currentPermissionParent, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }
  
  changepassword() {
    let that = this;
    that.userService.changePassword(that.id, that.password, that.newpassword).subscribe(
      data => {
        if (data.value === true) {
          that.toastrService.success(that.rss.savesuccessfully);
          that.password = '', that.newpassword = '', that.confirmnewpassword = '';
        }
        else
          that.toastrService.error(that.rss.wrongpassword);
      },
      error => {
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      }
    );
  }

  //Validation below
  isValid(): boolean {
    let that = this;
    return that.isValidPassword() && that.isValidNewPassword() && that.isValidConfirmNewPassword();
  }
  isValidPassword(): boolean {
    return this.password.trim() != '';
  }
  isValidNewPassword(): boolean {
    return this.newpassword.trim() != '';
  }
  isValidConfirmNewPassword(): boolean {
    let that = this;
    return that.isValidNewPassword() && that.confirmnewpassword == that.newpassword;
  }
  message(): string {
    let that = this;
    let messages: string[] = [];
    if (!that.isValidPassword()) messages.push(that.rss.password + ' ' + that.rss.cantbeblank);
    if (!that.isValidNewPassword()) messages.push(that.rss.newpassword + ' ' + that.rss.cantbeblank);
    if (!that.isValidConfirmNewPassword()) messages.push(that.rss.confirmpassword + ' ' + that.rss.doesnotmatch);
    return messages.join(', ');
  }
}
