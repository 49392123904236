import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubjectService } from 'src/app/service/subject.service';
import { ContentCategoryModel } from 'src/app/model/contentcategory.model';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'custom-client-categories',
  templateUrl: './categories.component.html'
})
export class CustomClientCategoriesComponent implements OnInit {
  @Input('idcontent') idcontent = 0;
  @Output('categoriesEvent') categoriesEvent = new EventEmitter<ContentCategoryModel[]>();
  allcategories: ContentCategoryModel[];

  constructor(private apiService: ApiService, private subjectService: SubjectService) { }
  ngOnInit() {
    let that = this;
    that.allcategories = [];

    that.apiService.get<ContentCategoryModel[]>('client', 'content', 0, `GetCategoryByIdcontent?idcontent=${that.idcontent}`).subscribe(categories => {
      that.allcategories = categories;
      that.categoriesEvent.emit(categories);
    });

    that.subjectService.currentLanguageCode$.subscribe(lang => {
      new ContentCategoryModel(0, []).changeListContentCategoryLanguage(that.allcategories, lang);
      that.categoriesEvent.emit(that.allcategories);
    });
  }
}
