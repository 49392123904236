import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { TagModel } from 'src/app/model/tag.model';

@Component({
  selector: 'admin-tag',
  templateUrl: './tag.component.html',
})
export class AdminTagComponent extends AdminLayoutComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
      new TagModel([]).changeListTagLanguage(that.listData, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.controller = 'tag';
    that.activatedRoute.queryParams.subscribe(params => that.getPage());
  }
}
