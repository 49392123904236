import { Component, OnInit, Input } from '@angular/core';
import { SubjectService } from 'src/app/service/subject.service';
import { Resource } from 'src/app/resource/resource';
import { ContentCategoryModel } from 'src/app/model/contentcategory.model';

@Component({
  selector: 'custom-client-categoriesanchor',
  templateUrl: './categoriesanchor.component.html'
})
export class CustomClientCategoriesAnchorComponent implements OnInit {
  @Input('rss') rss: Resource;
  @Input('allcategories') allcategories: ContentCategoryModel[];
  
  constructor(private subjectService: SubjectService) { }
  ngOnInit() {
    let that = this;
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }
}
