import { LanguageModel } from './language.model';
import { SimpleTagTranslationModel } from './simpletagtranslation.model';

class SimpleTag {
  id: number;
  name: string;

  constructor() {
    this.id = 0;
    this.name = '';
  }
}

export class SimpleTagModel {
  tag: SimpleTag;
  listTranslation: SimpleTagTranslationModel[];

  constructor(languages: LanguageModel[]) {
    let that = this;
    that.tag = new SimpleTag();
    that.listTranslation = [];
    languages.forEach(lang => that.listTranslation.push(new SimpleTagTranslationModel(lang.isoCode)));
  }

  changeListSimpleTagLanguage(list: SimpleTagModel[], iso_code: string): SimpleTagModel[] {
    list.forEach(model => {
      if (model.listTranslation && model.listTranslation.length > 0) {
        let lt = model.listTranslation.find(x => x.lang == iso_code);
        if (lt) model.tag.name = lt.name;
      }
    });
    return list;
  }
}
