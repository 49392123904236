import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { MasterData } from 'src/app/enum/masterdata.enum';
import { LinkModel } from 'src/app/model/link.model';
import { MasterDataModel } from 'src/app/model/masterdata.model';

@Component({
  selector: 'admin-link',
  templateUrl: './link.component.html'
})
export class AdminLinkComponent extends AdminLayoutComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
      new LinkModel([]).changeListLinkLanguage(that.listData, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.controller = 'link';
    that.activatedRoute.queryParams.subscribe(params => {
      if (!params['selected']) that.selected = 0;
      that.loadlinkids();
      that.queryString = [
        { Key: 'page', Value: that.page },
        { Key: 'pageSize', Value: that.pageSize },
        { Key: 'selected', Value: that.selected }
      ];
      that.getPage();
    });
  }

  selected: number = 0;
  linkids: MasterDataModel[];
  linkid: MasterDataModel[];
  linkidSettings: any = {};
  changelinkid(item: any) {
    this.selected = item.value;
  }
  removelinkid(item: any) {
    this.selected = 0;
  }
  private loadlinkids() {
    let that = this;
    that.linkids = that.masterdata.filter(x => x.id == MasterData.Link && x.lang == that.currentLanguage.isoCode && x.value > 0);
    that.linkid = [that.linkids.find(x => x.value == that.selected)];
    that.linkidSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: 'value',
      textField: 'name'
    };
  }
}
