import { Input, Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'custom-client-commentfacebook',
  templateUrl: './commentfacebook.component.html'
})
export class CustomClientCommentFacebookComponent implements OnInit {
  @Input('idcontent') idcontent = 0;
  @Input('take') take = 0;
  url = window.location.href.split('?')[0];
  node: HTMLScriptElement;

  constructor() {
    this.node = document.createElement('script');
    this.node.innerHTML = '(function (d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = "https://connect.facebook.net/vi_VN/sdk.js#xfbml=1&version=v3.1&appId=2119950768016262&autoLogAppEvents=1"; fjs.parentNode.insertBefore(js, fjs); }(document, "script", "facebook-jssdk"));';
  }

  ngOnInit() { $('#fb-script').append(this.node); }
}
