import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from '../layout/layout.component';

import { Status } from 'src/app/enum/status.enum';
import { Permission } from 'src/app/enum/permission.enum';

import { PermissionModel } from 'src/app/model/permission.model';

@Component({
  selector: 'shared-admin-sidebar',
  templateUrl: './sidebar.component.html'
})
export class AdminSidebarComponent extends AdminLayoutComponent implements OnInit {
  parents: PermissionModel[];
  childs: PermissionModel[];
  ngOnInit() {
    super.ngOnInit();
    this.userService.currentUser$.subscribe(user => {
      let menu: PermissionModel[] = user.permissions.filter(x => x.permissionBit != Permission.Admin && x.permissionBit != Permission.Personal && x.statusId == Status.Activated);
      this.parents = menu.filter(x => x.parentId == null);
      this.childs = menu.filter(x => x.parentId > 0);
    });
    this.permissionService.currentPermission$.subscribe(p => this.currentPermission = p);
  }

  hasChild(parent: PermissionModel): boolean {
    let childs = this.childs.filter(x => x.parentId == parent.id);
    return childs && childs.length > 0;
  }
}
