import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { SelectModel } from 'src/app/model/select.model';
import { SubjectService } from 'src/app/service/subject.service';

@Component({
  selector: 'ng-multiselect-dropdown-content-link',
  template: `<ng-multiselect-dropdown [data]="linkids"
                                      [(ngModel)]="linkid"
                                      [settings]="linkidSettings"
                                      (onSelect)="changelinkid($event)"
                                      (onDeSelect)="changelinkid($event)">
             </ng-multiselect-dropdown>`
})
export class NgMultiselectDropdownContentLinkComponent implements OnInit {
  @Input() id: number;
  @Input('area') area: string;
  @Input('controller') controller: string;
  @Output() linkEvent = new EventEmitter<SelectModel[]>();

  constructor(private apiService: ApiService, private service: SubjectService) { }

  ngOnInit() {
    let that = this;
    that.apiService.get<SelectModel[]>(that.area, that.controller, that.id, 'GetLinks').subscribe(
      links => {
        that.linkids = links;
        that.linkid = links.filter(x => x.selected);
        that.loadlinkid();
        that.linkEvent.emit(that.linkids);
      }
    );
    that.service.selects$.subscribe(selects => {
      that.linkid = selects.filter(x => x.selected);
    });
  }

  linkids: SelectModel[];
  linkid: SelectModel[];
  linkidSettings: any = {};
  private changelinkid(item: any) {
    let old = this.linkids.find(x => x.id == item.id);
    if (old) {
      let index = this.linkids.indexOf(old);
      old.selected = !old.selected;
      this.linkids[index] = old;
      this.linkEvent.emit(this.linkids);
    }
  }
  private loadlinkid() {
    this.linkidSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name'
    };
  }
}
