import { Component, OnInit } from '@angular/core';
import { ClientLayoutComponent } from 'src/app/shared/client/layout/layout.component';
import { SimpleContentModel } from 'src/app/model/simplecontent.model';
import { TagModel } from 'src/app/model/tag.model';
import { SimpleTagModel } from 'src/app/model/simpletag.model';

@Component({
  selector: 'client-news',
  templateUrl: './news.component.html'
})
export class ClientNewsComponent extends ClientLayoutComponent implements OnInit {
  tags: SimpleTagModel[];

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.controller = 'content';
    that.tags = [];

    that.apiService.get<SimpleTagModel[]>(that.area, 'tag', that.currentContent.id, 'GetByIDContent').subscribe(tags => that.tags = tags);
    
    that.subjectService.currentLanguageCode$.subscribe(lang => {
      new SimpleContentModel([]).changeListSimpleContentLanguage(that.listData, lang);
      new SimpleTagModel([]).changeListSimpleTagLanguage(that.tags, lang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.activatedRoute.queryParams.subscribe(params => {
      that.currentSearchTag = params['tagid'] ? params['tagid'] : 0;
      that.pageSize = that.currentContent.take;
      that.queryString = [
        { Key: 'page', Value: that.page },
        { Key: 'pageSize', Value: that.currentContent.take },
        { Key: 'id', Value: that.currentContent.id },
        { Key: 'templateid', Value: that.currentContent.templateId },
        { Key: 'tagid', Value: that.currentSearchTag },
        { Key: 'keyword', Value: that.keyword }
      ];
      that.getPage();
    });
  }

  currentSearchTag = 0;
  private changetagid(newValue: number) {
    this.page = 1;
    this.currentSearchTag = newValue;
  }
}
