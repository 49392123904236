export class ContentCategoryTranslationModel {
  idcontentcategory: number;
  lang: string;
  name: string;
  url: string;
  fullContent: string;

  constructor(isoCode = '') {
    this.idcontentcategory = 0;
    this.lang = isoCode;
    this.name = '';
    this.url = '';
    this.fullContent = '';
  }
}
