import { TagTranslationModel } from './tagtranslation.model';
import { LanguageModel } from './language.model';

export class TagModel {
  id: number;
  name: string;
  viewCount: number;
  statusId: number;
  listTranslation: TagTranslationModel[];

  constructor(languages: LanguageModel[]) {
    let that = this;

    that.id = 0;
    that.name = '';
    that.viewCount = 0;
    that.statusId = 3;

    that.listTranslation = [];
    languages.forEach(function (language) { that.listTranslation.push(new TagTranslationModel(language.isoCode)) });
  }

  changeListTagLanguage(list: TagModel[], iso_code: string): TagModel[] {
    if (list && list.length > 0) {
      list.forEach(link => {
        if (link.listTranslation && link.listTranslation.length > 0) {
          let lt = link.listTranslation.find(x => x.lang == iso_code);
          if (lt) link.name = lt.name;
        }
      });
    }
    return list;
  }
}
