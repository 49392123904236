import { ContentModel } from './content.model';
import { SelectsModel } from './selects.model';
import { LanguageModel } from './language.model';
export class AdminContentModel {
  content: ContentModel;
  models: SelectsModel[];

  constructor(languages: LanguageModel[]) {
    this.content = new ContentModel(languages);
    this.models = [];
  }
}
