import { Component, OnInit } from '@angular/core';
import { ClientLayoutComponent } from 'src/app/shared/client/layout/layout.component';
import { ContentModel } from 'src/app/model/content.model';

@Component({
  selector: 'client-about',
  templateUrl: './about.component.html'
})
export class ClientAboutComponent extends ClientLayoutComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }
}
