import { ContentModel } from '../model/content.model';
import { LinkModel } from '../model/link.model';
import { ContentLinkCustomModel } from '../model/contentlinkcustom.model';

export class SessionStorage {
  languages = 'languages';
  appSettings = 'appSettings';
  masterdata = 'masterdata';

  adminCurrentUser = 'adminCurrentUser';
  clientCommon = 'clientCommon'; // ClientCommonModel[]: set from app.service.ts
  
  clientContentAnalysis = 'clientContentAnalysis'; // ContentAnalysisModel

  currentContent = 'currentContent'; // ContentModel

  clientContents = 'clientContents'; // ContentModel[]: get content
  clientContentLinks = 'clientContentLinks'; // ContentLinkCustomModel[]: get link id of content id, if content id is existed, do not get
  clientLinks = 'clientLinks'; // LinkModel[]: get link

  setItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any {
    let result = sessionStorage.getItem(key);
    if (result == null)
      return '';
    return JSON.parse(result);
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  hasValue(key: string): boolean {
    let value = this.getItem(key);
    return value != '';
  }

  getLength(): number {
    return sessionStorage.length;
  }

  setClientContents(contents: ContentModel[]) {
    let that = this;
    let sssContents = that.getItem(that.clientContents);
    if (sssContents == '')
      that.setItem(that.clientContents, contents);
    else {
      let newIds: number[] = [];
      let newContents: ContentModel[] = [];
      contents = contents.concat(that.getItem(that.clientContents));
      contents.forEach(c => {
        if (!newIds.includes(c.id)) {
          newContents.push(c);
          newIds.push(c.id);
        }
      });
      that.setItem(that.clientContents, newContents);
    }
  }

  setClientLinks(links: LinkModel[]) {
    let that = this;
    let ssslinks = that.getItem(that.clientLinks);
    if (ssslinks == '')
      that.setItem(that.clientLinks, links);
    else {
      let newIds: number[] = [];
      let newLinks: LinkModel[] = [];
      links = links.concat(that.getItem(that.clientLinks));
      links.forEach(c => {
        if (!newIds.includes(c.id)) {
          newLinks.push(c);
          newIds.push(c.id);
        }
      });
      that.setItem(that.clientLinks, newLinks);
    }
  }

  setClientContentLinks(idcontent: number, idlinks: number[]) {
    let that = this;
    let cl = new ContentLinkCustomModel(idcontent, idlinks);
    let cls = that.getItem(that.clientContentLinks);
    if (cls == '')
      cls = [cl];
    else {
      let clExisted = cls.find(x => x.idcontent == idcontent);
      if (!clExisted)
        cls.push(cl);
      else {
        let index = cls.indexOf(clExisted);
        cls[index] = cl;
      }
    }
    that.setItem(that.clientContentLinks, cls);
  }
}
