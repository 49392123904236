import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { SelectModel } from 'src/app/model/select.model';

@Component({
  selector: 'ng-multiselect-dropdown-content-tag',
  template: `<ng-multiselect-dropdown [data]="tagids"
                                      [(ngModel)]="tagid"
                                      [settings]="tagidSettings"
                                      (onSelect)="changetagid($event)"
                                      (onDeSelect)="changetagid($event)">
             </ng-multiselect-dropdown>`
})
export class NgMultiselectDropdownContentTagComponent implements OnInit {
  @Input() id: number;
  @Input('area') area: string;
  @Input('controller') controller: string;
  @Output() tagEvent = new EventEmitter<SelectModel[]>();

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    let that = this;
    that.apiService.get<SelectModel[]>(that.area, that.controller, that.id, 'GetTags').subscribe(
      tags => {
        that.tagids = tags;
        that.tagid = tags.filter(x => x.selected);
        that.loadtagid();
        that.tagEvent.emit(that.tagids);
      }
    );
  }

  tagids: SelectModel[];
  tagid: SelectModel[];
  tagidSettings: any = {};
  private changetagid(item: any) {
    let old = this.tagids.find(x => x.id == item.id);
    if (old) {
      let index = this.tagids.indexOf(old);
      old.selected = !old.selected;
      this.tagids[index] = old;
      this.tagEvent.emit(this.tagids);
    }
  }
  private loadtagid() {
    this.tagidSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      enableCheckAll: false,
      idField: 'id',
      textField: 'name'
    };
  }
}
