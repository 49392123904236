import { Component, OnInit } from '@angular/core';
import { ClientLayoutComponent } from 'src/app/shared/client/layout/layout.component';

@Component({
  selector: 'client-projectdetail',
  templateUrl: './projectdetail.component.html'
})
export class ClientProjectDetailComponent extends ClientLayoutComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }
}
