declare var grecaptcha: any;
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AppSettingsModel } from 'src/app/model/appsettings.model';
import { SubjectService } from 'src/app/service/subject.service';

@Component({
  selector: 'custom-googlerecaptcha',
  template: `<div class="g-recaptcha" [attr.data-sitekey]="appSettings.siteKey" style="display:inline-block;float:left;padding-bottom:20px"></div>`
})
export class CustomGoogleRecaptchaComponent implements OnInit {
  @Input('appSettings') appSettings: AppSettingsModel;
  @Output() isAuthenticatedEvent = new EventEmitter<boolean>();
  @Output() responseEvent = new EventEmitter<string>();

  constructor(private subjectService: SubjectService) {
    let node = document.createElement('script');
    node.src = 'https://www.google.com/recaptcha/api.js';
    node.charset = 'utf-8';
    node.type = 'text/javascript';

    let customgooglerecaptcha = document.getElementById('customgooglerecaptcha');
    customgooglerecaptcha.innerHTML = '';
    customgooglerecaptcha.appendChild(node);
  }
  
  ngOnInit() {
    let that = this;
    that.subjectService.reloadgooglerecaptcha$.subscribe(reload => {
      if (reload) grecaptcha.reset();
      else {
        let response = grecaptcha.getResponse();
        that.responseEvent.emit(response);
        that.isAuthenticatedEvent.emit(!(response.length === 0));
      }
    });
  }
}
