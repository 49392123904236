export enum MasterData {
  Status = 1,
  AdministrativeUnitLevel = 2,
  Permission = 3,
  Method = 4,
  Log = 5,
  Template = 6,
  Link = 7,
  Target = 8
}
