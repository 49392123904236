export class FeedbackModel {
  id: number;
  name: string;
  phone: string;
  email: string;
  address: string;
  detail: string;
  statusId: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;

  modifiedByNavigation_Name: string;
  modifiedByNavigation_Id: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.phone = '';
    this.email = '';
    this.address = '';
    this.detail = '';
    this.statusId = 3;
    this.createdDate = new Date();
    this.modifiedBy = 0;
    this.modifiedDate = new Date();

    this.modifiedByNavigation_Id = 0;
    this.modifiedByNavigation_Name = '';
  }
}
