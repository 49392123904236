import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SessionStorage } from 'src/app/others/sessionstorage';
import { ContentAllProjectsModel } from 'src/app/model/contentallprojects.model';
import { SimpleContentModel } from 'src/app/model/simplecontent.model';
import { SimpleContentTranslationModel } from 'src/app/model/simplecontenttranslation.model';
import { Link } from 'src/app/enum/link.enum';
import { ApiService } from 'src/app/service/api.service';
import { SubjectService } from 'src/app/service/subject.service';
import { LanguageService } from 'src/app/service/language.service';
import { Resource } from 'src/app/resource/resource';

@Component({
  selector: 'custom-client-allprojects',
  templateUrl: './allprojects.component.html'
})
export class CustomClientAllProjectsComponent implements OnInit {
  @Input('rss') rss: Resource;
  @Output() allprojectsEvent = new EventEmitter<boolean>();

  allprojects: ContentAllProjectsModel;
  allprojectslogos: SimpleContentModel[];
  projectimages: string[];
  allprojectsLoaded = false;
  offset = 100;

  projectoptions = {
    item: 1,
    autoWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    autoplay: true,
    responsiveClass: true,
    loop: true,
    prevArrow: '.arrow-slider .fa-chevron-circle-left',
    nextArrow: '.arrow-slider .fa-chevron-circle-right'
  }
  allprojectsoptions = {
    items: 1,
    loop: true,
    center: false,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    callbacks: true,
    responsive: {
      480: {
        items: 1
      },
      768: {
        items: 2
      },
      992: {
        items: 4,
        nav: false
      }
    }
  }
  allprojectslogosoptions = {
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    items: 2,
    loop: true,
    center: false,
    padding: 10,
    margin: 20,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: false,
    callbacks: true,
    responsive: {
      480: {
        items: 2
      },
      768: {
        items: 3
      },
      992: {
        items: 4
      },
      1140: {
        items: 6
      }
    }
  }

  constructor(private apiService: ApiService, private subjectService: SubjectService, private languageService: LanguageService) { }

  ngOnInit() {
    let that = this;
    that.allprojectslogos = [];
    that.projectimages = [];
    that.allprojects = new ContentAllProjectsModel([]);

    that.apiService.get<ContentAllProjectsModel>('client', 'content', 0, 'GetAllProjects').subscribe(data => {
      that.setdata(data);
      that.allprojectsEvent.emit(true);

      let sss = new SessionStorage();
      sss.setClientContents([data.project]);
      sss.setClientLinks(data.links);
      sss.setClientContentLinks(data.project.id, data.links.map(x => x.id));
    });

    that.subjectService.currentLanguageCode$.subscribe(newLang => new ContentAllProjectsModel([]).changeContentAllProjectsLanguage(that.allprojects, newLang));
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }

  private setdata(data: ContentAllProjectsModel) {
    let that = this;
    new ContentAllProjectsModel([]).changeContentAllProjectsLanguage(data, that.languageService.getCurrentLanguageCode());

    that.allprojects.project = data.project;
    that.allprojects.links = data.links;

    data.projects.forEach(p => {
      p.listImage.forEach(img => {
        let item = new SimpleContentModel([]);
        item.content = p.content;
        item.listTranslation = p.listTranslation;

        if (img.toLowerCase().includes('logo')) {
          item.listImage = [img];
          that.allprojectslogos.push(item);
        } else if (img.trim() != '') {
          item.listImage = [img];
          that.allprojects.projects.push(item);
        }
      });
    });

    let projectlogo = that.allprojects.links.find(x => x.linkId == Link.Logo);
    if (projectlogo) {
      let item = new SimpleContentModel([]);
      item.content = new SimpleContentModel([]).convert(that.allprojects.project);
      item.listTranslation = new SimpleContentTranslationModel('').convertList(that.allprojects.project.listTranslation);
      item.listImage = [projectlogo.image];
      that.allprojectslogos.push(item);
    }

    that.projectimages = that.allprojects.links.filter(x => x.linkId == Link.ImageDetail).map(x => x.image);

    that.allprojectsLoaded = true;
    that.allprojectsEvent.emit(true);
  }
}
