import { Component } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'status-404',
  template: `<div id="notfound">
		<div class="notfound">
			<div class="notfound-404">
				<h1>404</h1>
			</div>
			<h2>Oops, The Page you are looking for can't be found!</h2>
			<a href="#" (click)="back()"><span class="arrow"></span>Go Back</a>
		</div>
	</div>`,
  styleUrls: ['./404.component.css']
})
export class Status404Component {
  constructor(private location: Location) { }
  back = () => this.location.back();
}
//Nam giua <h2> - <a>
//<form class="notfound-search" >
//  <input type="text" placeholder = "Search..." >
//    <button type="button" > Search < /button>
//      < /form>
