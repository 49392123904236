import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';

@Component({
  selector: 'admin-masterdata',
  templateUrl: './masterdata.component.html',
})
export class AdminMasterDataComponent extends AdminLayoutComponent implements OnInit {
  // CHUA LAM
  ngOnInit() {
    super.ngOnInit();
    let that = this;
    if (that.currentLanguage)
      that.listData = that.masterdata.filter(x => x.lang == that.currentLanguage.isoCode) as [];
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.listData = that.masterdata.filter(x => x.lang == newLang) as [];
      that.changePermissionLanguage(that.currentPermission, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }

  getTypeName(id: number): string {
    let that = this;
    let type = that.masterdata.find(x => x.id == id && x.value == 0 && x.lang == that.languageService.getCurrentLanguageCode());
    if (type)
      return type.name;
    return '';
  }
}
