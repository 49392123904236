import { SimpleContentTranslationModel } from './simplecontenttranslation.model';
import { LanguageModel } from './language.model';
import { ContentModel } from './content.model';
import { SimpleTagModel } from './simpletag.model';

class SimpleContent {
  id: number;
  name: string;
  briefContent: string;
  url: string;
  viewCount: number;
  displayOrder: number;
  createdDate: Date;

  constructor() {
    this.id = 0;
    this.name = '';
    this.briefContent = '';
    this.url = '';
    this.viewCount = 0;
    this.displayOrder = 0;
    this.createdDate = new Date();
  }
}

export class SimpleContentModel {
  content: SimpleContent;
  listTranslation: SimpleContentTranslationModel[];
  listTag: SimpleTagModel[];
  listImage: string[];

  constructor(languages: LanguageModel[]) {
    let that = this;
    that.content = new SimpleContent();
    that.listTranslation = [];
    that.listTag = [];
    that.listImage = [];

    languages.forEach(lang => that.listTranslation.push(new SimpleContentTranslationModel(lang.isoCode)));
    that.listTag.push(new SimpleTagModel(languages));
  }

  changeListSimpleContentLanguage(list: SimpleContentModel[], iso_code: string): SimpleContentModel[] {
    if (list && list.length > 0) {
      list.forEach(model => {
        if (model.listTranslation && model.listTranslation.length > 0) {
          let lt = model.listTranslation.find(x => x.lang == iso_code);
          if (lt) {
            model.content.name = lt.name;
            model.content.briefContent = lt.briefContent;
            model.content.url = lt.url;
          }
        }
        if (model.listTag && model.listTag.length > 0) model.listTag = new SimpleTagModel([]).changeListSimpleTagLanguage(model.listTag, iso_code);
      });
    }
    return list;
  }

  convert(model: ContentModel): SimpleContent {
    let content = new SimpleContent();
    content.id = model.id;
    content.name = model.name;
    content.briefContent = model.briefContent;
    content.url = model.url;
    content.viewCount = model.viewCount;
    content.displayOrder = model.displayOrder;
    content.createdDate = model.createdDate;
    return content;
  }
}
