export enum Permission {
  User = 1,
  Personal = 2,
  Admin = 4,
  Select = 8,
  SelectAll = 16,
  Insert = 32,
  Update = 64,
  UpdateAll = 128,
  Delete = 256,
  DeleteAll = 512
}
