import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProgressModel } from '../model/progress.model';

@Injectable({ providedIn: 'root' })
export class ProgressService {
  progress$ = new Subject<ProgressModel>();
  change(val: ProgressModel) {
    this.progress$.next(val);
  }

  //Todo
  changeWithPercent(percent: number, needload: number, thisloaded: number, thistotal: number) {
    let loaded = needload + thisloaded - thistotal;
    let percentLoaded = Math.round(100 * loaded / needload);
    let newLoaded = (percent - percentLoaded) * needload;
    this.change({ loaded: newLoaded, total: 0 });
  }
}
