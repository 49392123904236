import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';

@Component({
  selector: 'admin-user',
  templateUrl: './user.component.html',
})
export class AdminUserComponent extends AdminLayoutComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionChildren, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.controller = 'user';
    that.activatedRoute.queryParams.subscribe(params => that.getPage());
  }
}
