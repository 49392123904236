import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, Route } from '@angular/router';

import { SessionStorage } from '../others/sessionstorage';
import { Template } from '../enum/template.enum';
import { ClientCommonModel } from '../model/clientcommon.model';
import { PermissionModel } from '../model/permission.model';
import { UserModel } from '../model/user.model';

import { AppSettingsService } from './appsettings.service';
import { LanguageService } from './language.service';
import { MasterDataService } from './masterdata.service';

import { AdminLayoutComponent } from '../shared/admin/layout/layout.component';
import { ClientLayoutComponent } from '../shared/client/layout/layout.component';

import { AdminMasterDataComponent } from '../area/admin/masterdata/masterdata.component';
import { AdminPermissionComponent } from '../area/admin/permission/permission.component';
import { AdminPermissionInfoComponent } from '../area/admin/permissioninfo/permissioninfo.component';
import { AdminUserComponent } from '../area/admin/user/user.component';
import { AdminUserEditAuthorizeComponent } from '../area/admin/usereditauthorize/usereditauthorize.component';
import { AdminUserEditPasswordComponent } from '../area/admin/usereditpassword/usereditpassword.component';
import { AdminUserInfoComponent } from '../area/admin/userinfo/userinfo.component';
import { AdminContentComponent } from '../area/admin/content/content.component';
import { AdminContentInfoComponent } from '../area/admin/contentinfo/contentinfo.component';
import { AdminContentEditImageComponent } from '../area/admin/contenteditimage/contenteditimage.component';
import { AdminContentEditCategoryComponent } from '../area/admin/contenteditcategory/contenteditcategory.component';
import { AdminFeedbackComponent } from '../area/admin/feedback/feedback.component';
import { AdminFeedbackEditComponent } from '../area/admin/feedbackedit/feedbackedit.component';
import { AdminHomeComponent } from '../area/admin/home/home.component';
import { AdminLinkComponent } from '../area/admin/link/link.component';
import { AdminLinkInfoComponent } from '../area/admin/linkinfo/linkinfo.component';
import { AdminTagComponent } from '../area/admin/tag/tag.component';
import { AdminTagInfoComponent } from '../area/admin/taginfo/taginfo.component';

import { ClientLoginComponent } from '../area/client/login/login.component';
import { ClientAboutComponent } from '../area/client/about/about.component';
import { ClientContactComponent } from '../area/client/contact/contact.component';
import { ClientHomeComponent } from '../area/client/home/home.component';
import { ClientOverviewComponent } from '../area/client/overview/overview.component';
import { ClientNewsComponent } from '../area/client/news/news.component';
import { ClientNewsDetailComponent } from '../area/client/newsdetail/newsdetail.component';
import { ClientProjectComponent } from '../area/client/project/project.component';
import { ClientProjectDetailComponent } from '../area/client/projectdetail/projectdetail.component';
import { ClientVideoComponent } from '../area/client/video/video.component';

import { Status404Component } from '../area/status/404.component';

@Injectable()
export class AppService {
  constructor(
    private injector: Injector,
    private http: HttpClient,
    private appSettingsService: AppSettingsService,
    private languageService: LanguageService,
    private masterDataService: MasterDataService
  ) { }

  loadSettings(): Promise<any> {
    let that = this;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const router = that.injector.get(Router);

        let sss = new SessionStorage();
        that.getDefaultData(sss);

        if (window.location.pathname.startsWith('/admin')) {
          let adminCurrentUser = sss.getItem(sss.adminCurrentUser);
          let admin: Route[] = [];
          if (adminCurrentUser == '') {
            that.http.get('/api/admin/common').subscribe(
              data => {
                let user = data as UserModel;
                let permissionsHasUrl = user.permissions.filter(x => x.url != null && x.url != '' && x.templateId > 0);
                sss.setItem(sss.adminCurrentUser, user);

                admin = that.setAdmin(permissionsHasUrl);
                router.config.push({ path: 'admin', component: AdminLayoutComponent, children: admin });
                resolve(true);
              },
              error => {
                if (error.status == 401) {
                  sss.removeItem(sss.adminCurrentUser);
                  window.location.href = '/login?q=' + window.location.pathname;
                }
              }
            );
          } else {
            let permissionsHasUrl = adminCurrentUser.permissions.filter(x => x.url != null && x.url != '' && x.templateId > 0);
            admin = that.setAdmin(permissionsHasUrl);
            router.config.push({ path: 'admin', component: AdminLayoutComponent, children: admin });
            resolve(true);
          }
        }
        else {
          let client: Route[] = [];
          let clientCommon = sss.getItem(sss.clientCommon);
          //router.config.push({ path: '**', redirectTo: '' }); //TODO: otherwise redirect to home
          router.config.push({ path: 'login', component: ClientLoginComponent });
          if (clientCommon == '') {
            that.http.get<ClientCommonModel[]>('/api/client/common').subscribe(
              data => {
                client = that.setClient(data);
                router.config.push({ path: '', component: ClientLayoutComponent, children: client });
                sss.setItem(sss.clientCommon, data);
                resolve(true);
              },
              error => console.log(error)
            );
          } else {
            client = that.setClient(clientCommon);
            router.config.push({ path: '', component: ClientLayoutComponent, children: client });
            resolve(true);
          }

        }
        //TODO: if fail: reject(false);
      });
    });
  }

  private getDefaultData(sss: SessionStorage) {
    if (!sss.hasValue(sss.appSettings))
      this.appSettingsService.get().subscribe(app => sss.setItem(sss.appSettings, app));
    if (!sss.hasValue(sss.masterdata))
      this.masterDataService.get().subscribe(md => sss.setItem(sss.masterdata, md));;
    if (!sss.hasValue(sss.languages))
      this.languageService.getLanguages().subscribe(languages => sss.setItem(sss.languages, languages));
  }

  private setAdmin(permissions: PermissionModel[]): Route[] {
    let admin: Route[] = [];
    admin.push({ path: '', component: AdminHomeComponent, pathMatch: 'full' });
    permissions.forEach(p => {
      p.url = p.url.replace('/admin/', '');
      switch (p.templateId) {
        case Template.AdminMasterData:
          admin.push({ path: p.url, component: AdminMasterDataComponent });
          break;
        case Template.AdminPermission:
          admin.push({ path: p.url, component: AdminPermissionComponent });
          break;
        case Template.AdminPermissionInfo:
          admin.push({ path: p.url, component: AdminPermissionInfoComponent });
          admin.push({ path: `${p.url}/:id`, component: AdminPermissionInfoComponent });
          break;
        case Template.AdminUser:
          admin.push({ path: p.url, component: AdminUserComponent });
          break;
        case Template.AdminUserEditAuthorize:
          admin.push({ path: p.url, component: AdminUserEditAuthorizeComponent });
          admin.push({ path: `${p.url}/:id`, component: AdminUserEditAuthorizeComponent });
          break;
        case Template.AdminUserEditPassword:
          admin.push({ path: p.url, component: AdminUserEditPasswordComponent });
          admin.push({ path: `${p.url}/:id`, component: AdminUserEditPasswordComponent });
          break;
        case Template.AdminUserInfo:
          admin.push({ path: p.url, component: AdminUserInfoComponent });
          admin.push({ path: `${p.url}/:id`, component: AdminUserInfoComponent });
          break;
        case Template.AdminContent:
          admin.push({ path: p.url, component: AdminContentComponent });
          break;
        case Template.AdminContentInfo:
          admin.push({ path: p.url, component: AdminContentInfoComponent });
          admin.push({ path: `${p.url}/:id`, component: AdminContentInfoComponent });
          break;
        case Template.AdminContentEditImage:
          admin.push({ path: p.url, component: AdminContentEditImageComponent });
          admin.push({ path: `${p.url}/:id`, component: AdminContentEditImageComponent });
          break;
        case Template.AdminContentEditCategory:
          admin.push({ path: p.url, component: AdminContentEditCategoryComponent });
          admin.push({ path: `${p.url}/:id`, component: AdminContentEditCategoryComponent });
          break;
        case Template.AdminFeedback:
          admin.push({ path: p.url, component: AdminFeedbackComponent });
          break;
        case Template.AdminFeedbackEdit:
          admin.push({ path: p.url, component: AdminFeedbackEditComponent });
          admin.push({ path: `${p.url}/:id`, component: AdminFeedbackEditComponent });
          break;
        case Template.AdminHome:
          admin.push({ path: p.url, component: AdminHomeComponent });
          break;
        case Template.AdminLink:
          admin.push({ path: p.url, component: AdminLinkComponent });
          break;
        case Template.AdminLinkInfo:
          admin.push({ path: p.url, component: AdminLinkInfoComponent });
          admin.push({ path: `${p.url}/:id`, component: AdminLinkInfoComponent });
          break;
        case Template.AdminTag:
          admin.push({ path: p.url, component: AdminTagComponent });
          break;
        case Template.AdminTagInfo:
          admin.push({ path: p.url, component: AdminTagInfoComponent });
          admin.push({ path: `${p.url}/:id`, component: AdminTagInfoComponent });
          break;
      }
    });
    admin.push({ path: '**', redirectTo: '/admin' }); //TODO: 404Page
    return admin;
  }

  private setClient(clients: ClientCommonModel[]): Route[] {
    let client: Route[] = [];
    clients.forEach(item => {
      if (item.url.startsWith('/')) item.url = item.url.substr(1);
      if (item.url != '') {
        switch (item.templateId) {
          case Template.Index:
            client.push({ path: item.url, component: ClientHomeComponent });
            break;
          case Template.About:
            client.push({ path: item.url, component: ClientAboutComponent });
            break;
          case Template.Contact:
            client.push({ path: item.url, component: ClientContactComponent });
            break;
          case Template.Overview:
            client.push({ path: item.url, component: ClientOverviewComponent });
            break;
          case Template.News:
            client.push({ path: item.url, component: ClientNewsComponent });
            break;
          case Template.NewsDetail:
            client.push({ path: item.url, component: ClientNewsDetailComponent });
            break;
          case Template.Project:
            client.push({ path: item.url, component: ClientProjectComponent });
            break;
          case Template.ProjectDetail:
            client.push({ path: item.url, component: ClientProjectDetailComponent });
            break;
          case Template.Video:
            client.push({ path: item.url, component: ClientVideoComponent });
            break;
        }
      }
    });
    client.push({ path: '', component: ClientHomeComponent, pathMatch: 'full' });

    client.push({ path: '404', component: Status404Component });
    client.push({ path: '**', redirectTo: '/404' });

    return client;
  }
}
