import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { SubjectService } from './subject.service';

import { Resource } from '../resource/resource';
import { English } from '../resource/english';
import { Vietnamese } from '../resource/vietnamese';

import { LanguageModel } from '../model/language.model';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private http: HttpClient, private cookieService: CookieService, private subjectService: SubjectService) { }

  getLanguages(): Observable<LanguageModel[]> {
    return this.http.get<LanguageModel[]>('/api/language');
  }

  getCurrentLanguageCode(defaultLanguage: string = 'vi'): string {
    var result = this.cookieService.get('CurrentLanguage');
    if (result == '') {
      this.changeCurrentLanguage(defaultLanguage);
      result = defaultLanguage;
    }
    return result;
  }

  changeCurrentLanguage(iso_code: string) {
    this.cookieService.set('CurrentLanguage', iso_code);
    this.http.post('/api/language/ChangeCurrentLanguage?iso_code=' + iso_code, iso_code).subscribe();
    this.subjectService.currentLanguageCode$.next(iso_code);
  }
  
  getResource(iso_code: string = 'vi'): Resource {
    var rss: Resource;

    if (iso_code == 'en')
      rss = new English();
    else
      rss = new Vietnamese();

    return rss;
  }

  changeCurrentResource(iso_code: string = 'vi') {
    let rss: Resource;
    if (iso_code == 'en')
      rss = new English();
    else
      rss = new Vietnamese();
    this.subjectService.rss$.next(rss);
  }
}
