import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { JsonResultModel } from 'src/app/model/jsonresult.model';
import { TagModel } from 'src/app/model/tag.model';

@Component({
  selector: 'admin-tag-info',
  templateUrl: './taginfo.component.html'
})
export class AdminTagInfoComponent extends AdminLayoutComponent implements OnInit {
  tag: TagModel;

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.controller = 'tag';
    that.tag = new TagModel(that.languages);
    that.backUrl = that.currentPermissionFamily && that.currentPermissionFamily.length > 0 ? that.currentPermissionFamily[0].url : '/admin';

    that.activatedRoute.queryParams.subscribe(params => {
      let id = params['id'] ? params['id'] : 0;
      if (id > 0) {
        that.apiService.get<TagModel>(that.area, that.controller, id).subscribe(
          tag => {
            that.languages.forEach(language => {
              if (!tag.listTranslation.find(x => x.lang == language.isoCode)) {
                tag.listTranslation.push({ lang: language.isoCode, name: '' });
              }
            });
            that.tag = tag;
          },
          error => window.location.href = '/admin'
        );
      }
      else
        that.tag = new TagModel(that.languages);
    });
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }
  
  saveTag(type: string) {
    let that = this;
    that.apiService.post<JsonResultModel>(that.area, that.controller, that.tag, "PostTag").subscribe(
      data => {
        that.toastrService.success(that.rss.savesuccessfully);
        
        switch (type) {
          case that.rss.savecontinue:
            that.router.navigate([window.location.pathname.split('?')[0]]);
            break;
          case that.rss.saveclose:
            that.router.navigate([that.backUrl]);
            break;
          case that.rss.save:
            that.router.navigate([window.location.pathname], { queryParams: { id: data.value } });
            break;
        }
      },
      error => {
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      }
    );
  }
}
