import { ContentTranslationModel } from './contenttranslation.model';

export class SimpleContentTranslationModel {
  idcontent: number;
  lang: string;
  name: string;
  briefContent: string;
  url: string;

  constructor(isoCode: string) {
    this.idcontent = 0;
    this.lang = isoCode;
    this.name = '';
    this.briefContent = '';
    this.url = '';
  }

  convertList(models: ContentTranslationModel[]): SimpleContentTranslationModel[] {
    let translations: SimpleContentTranslationModel[] = [];
    models.forEach(model => {
      let t = new SimpleContentTranslationModel(model.lang);
      t.idcontent = model.idcontent;
      t.lang = model.lang;
      t.name = model.name;
      t.briefContent = model.briefContent;
      t.url = model.url;
      translations.push(t);
    });
    return translations;
  }
}
