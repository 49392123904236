import { Component, OnInit } from '@angular/core';

import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { MasterData } from 'src/app/enum/masterdata.enum';
import { JsonResultModel } from 'src/app/model/jsonresult.model';
import { MasterDataModel } from 'src/app/model/masterdata.model';
import { PermissionModel } from 'src/app/model/permission.model';

@Component({
  selector: 'admin-permission-info',
  templateUrl: './permissioninfo.component.html'
})
export class AdminPermissionInfoComponent extends AdminLayoutComponent implements OnInit {
  permission: PermissionModel;
  indexP: number;

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.controller = 'permission';
    that.backUrl = that.currentPermissionParent ? that.currentPermissionParent.url : '/admin';
    that.permission = new PermissionModel(that.languages);
    that.activatedRoute.queryParams.subscribe(params => {
      let id = 0;
      id = params['id'];

      that.getPermission(id);
      that.permissionService.getPermission([]).subscribe(data => console.log(data), error => window.location.href = '/admin');
      that.loadparent(id);
      that.loadtemplate();
    });
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
      that.changePermissionLanguage(that.currentPermissionParent, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }

  parents: PermissionModel[];
  parent: PermissionModel[];
  parentSettings: any = {};
  private changeparent(item: any) {
    this.permission.parentId = item.id;
  }
  private removeparent(item: any) {
    this.permission.parentId = null;
  }
  private loadparent(id: number) {
    let that = this;
    that.parents = that.currentUser.permissions.filter(x => x.parentId == null && x.id != id);
    that.parent = [that.parents.find(x => x.id == that.permission.parentId)];
    that.parentSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'name'
    };
  }

  templates: MasterDataModel[];
  template: MasterDataModel[];
  templateSettings: any = {};
  private changetemplate(item: any) {
    this.permission.templateId = item.value;
  }
  private removetemplate(item: any) {
    this.permission.templateId = 0;
  }
  private loadtemplate() {
    let that = this;
    that.templates = that.masterdata.filter(x => x.id == MasterData.Template && x.lang == that.currentLanguage.isoCode && x.name.includes('Admin'));
    that.template = [that.templates.find(x => x.value == that.permission.templateId)];
    that.templateSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: 'value',
      textField: 'name'
    };
  }

  savePermission(type: string) {
    let that = this;
    that.apiService.post<JsonResultModel>(that.area, that.controller, that.permission, "PostPermission").subscribe(
      data => {
        if (that.permission.id > 0)
          that.currentUser.permissions[that.indexP] = that.permission;
        else {
          that.permission.id = data.value;
          that.currentUser.permissions.push(that.permission);
        }

        that.userService.changeCurrentUser(that.currentUser);
        that.toastrService.success(that.rss.savesuccessfully);

        switch (type) {
          case that.rss.savecontinue:
            that.permission = new PermissionModel(that.languages);
            that.router.navigate([window.location.pathname.split('?')[0]]);
            break;
          case that.rss.saveclose:
            that.router.navigate([that.backUrl]);
            break;
          case that.rss.save:
            that.router.navigate([window.location.pathname], { queryParams: { id: data.value } });
            break;
        }
      },
      error => {
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      }
    );
  }

  deletePermission() {
    let that = this;
    that.delete(that.permission.id).subscribe(
      () => {
        let p = that.currentUser.permissions.find(x => x.id == that.permission.id);
        let index = that.currentUser.permissions.indexOf(p);
        that.currentUser.permissions.splice(index, 1);

        that.userService.changeCurrentUser(that.currentUser);
        that.toastrService.success(that.rss.deletesuccessfully);
        that.router.navigate([that.backUrl]);
      },
      error => {
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      }
    );
  }

  changePermissionBit(p: PermissionModel, val: number) {
    if (((val & 7) > 0 && p.permissionBit != val) || (p.permissionBit & 7) > 0)
      p.permissionBit = val;
    else if ((p.permissionBit & val) > 0)
      p.permissionBit -= val;
    else
      p.permissionBit += val;
  }

  hasChild() {
    let that = this;
    return that.currentUser.permissions.find(x => x.parentId == that.permission.id);
  }

  private getPermission(id: number) {
    let that = this;
    let permission = that.currentUser.permissions.find(x => x.id == id);
    that.permission = new PermissionModel(that.languages);
    if (permission) {
      that.permission = permission;
      that.indexP = that.currentUser.permissions.indexOf(permission);
    }
  }

  private formaturl() {
    let that = this;
    if (!that.permission.url.startsWith('/admin/'))
      that.permission.url = '/admin/' + that.permission.url;
  }

  //Validation below
  private isValid(): boolean {
    return this.isValidName() && this.isValidUrl();
  }
  private isValidName(): boolean {
    let result = true;
    this.permission.listTranslation.forEach(t => {
      if (t.name.trim() == '') result = false;
    });
    return result;
  }
  private isValidUrl(): boolean {
    let that = this;
    return that.permission.url == null || that.permission.url.trim() == '' ||
      !that.currentUser.permissions.find(x => x.url == that.permission.url.trim() && x.id != that.permission.id);
  }
  private message(): string {
    let that = this;
    let messages: string[] = [];
    if (!that.isValidName()) messages.push(that.rss.name + ' ' + that.rss.cantbeblank);
    if (!that.isValidUrl()) messages.push('URL ' + that.rss.existed);
    return messages.join(', ');
  }
}
