import { Status } from '../enum/status.enum';
import { PermissionTranslationModel } from './permissiontranslation.model';
import { LanguageModel } from './language.model';
import { UserModel } from './user.model';

export class PermissionModel {
  id: number;
  icon: string;
  url: string;
  displayOrder: number;
  permissionBit: number;
  parentId: number;
  statusId: number;
  templateId: number;

  name: string;
  description: string;

  listTranslation: PermissionTranslationModel[];

  constructor(languages: LanguageModel[]) {
    let that = this;

    that.id = 0;
    that.icon = '';
    that.url = '';
    that.displayOrder = 0;
    that.permissionBit = 0;
    that.parentId = null;
    that.statusId = 3;
    that.templateId = 0;
    that.name = '';
    that.description = '';

    that.listTranslation = [];
    languages.forEach(function (language) { that.listTranslation.push(new PermissionTranslationModel(language.isoCode)) });
  }

  getCurrentPermission(currentUser: UserModel): PermissionModel {
    if (currentUser == null)
      return new PermissionModel([]);
    else {
      let pathname = location.pathname.split('?')[0];
      return currentUser.permissions.find(p => p.url == pathname);
    }
  }

  getCurrentPermissionParent(currentUser: UserModel): PermissionModel {
    let result = new PermissionModel([]);
    if (currentUser != null && currentUser) {
      let currentPermission = this.getCurrentPermission(currentUser);
      if (currentPermission)
        result = currentUser.permissions.find(x => x.id == currentPermission.parentId && x.statusId == Status.Activated);
    }
    return result;
  }

  getCurrentPermissionChildren(currentUser: UserModel, notID: number = 0): PermissionModel[] {
    let result: PermissionModel[] = [];
    let currentPermission = this.getCurrentPermission(currentUser);
    if (currentPermission) {
      result = currentUser.permissions.filter(p => p.parentId == currentPermission.id && p.id != notID && p.statusId == Status.Activated);
    }
    return result;
  }

  getCurrentPermissionFamily(currentUser: UserModel): PermissionModel[] {
    let result: PermissionModel[] = [];
    let currentPermission = this.getCurrentPermission(currentUser);
    let parent = this.getCurrentPermissionParent(currentUser);
    if (currentUser != null && currentUser && currentPermission && parent) {
      result = currentUser.permissions.filter(p => p.parentId == parent.id && p.id != currentPermission.id && p.statusId == Status.Activated);
    }
    return result;
  }

  changePermissionLanguage(p: PermissionModel, iso_code: string): PermissionModel {
    if (p.listTranslation && p.listTranslation.length > 0) {
      let pt = p.listTranslation.find(x => x.lang == iso_code);
      p.name = pt.name;
      p.description = pt.description;
    }
    return p;
  }

  changeListPermissionLanguage(list: PermissionModel[], iso_code: string): PermissionModel[] {
    if (list && list.length > 0) {
      list.forEach(p => {
        if (p.listTranslation && p.listTranslation.length > 0) {
          let pt = p.listTranslation.find(x => x.lang == iso_code);
          p.name = pt.name;
          p.description = pt.description;
        }
      });
    }
    return list;
  }
}
