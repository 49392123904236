import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { SessionStorage } from '../others/sessionstorage';
import { JsonResultModel } from '../model/jsonresult.model';
import { UserModel } from '../model/user.model';
import { UserPermissionModel } from '../model/userpermission.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser$ = new Subject<UserModel>();
  constructor(private http: HttpClient) { }
  
  changeCurrentUser(user: UserModel) {
    this.currentUser$.next(user);
    let sss = new SessionStorage();
    sss.setItem(sss.adminCurrentUser, user);
  }

  changePassword(id: number, password: string, newpassword: string): Observable<JsonResultModel> {
    return this.http.put<JsonResultModel>('/api/admin/user/ChangePassword', { id: id, password: password, newpassword: newpassword });
  }

  changeUserPermission(id: number, list: UserPermissionModel[]): Observable<UserPermissionModel[]> {
    return this.http.put<UserPermissionModel[]>(`/api/admin/user/ChangeUserPermission`, { List: list, Id: id });
  }

  getUserPermission(id: number): Observable<UserPermissionModel[]> {
    return this.http.get<UserPermissionModel[]>(`/api/admin/user/GetUserPermission?id=${id}`);
  }
}
