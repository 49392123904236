import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

import { BaseLayoutComponent } from '../../base/layout/base-layout.component';
import { Permission } from 'src/app/enum/permission.enum';
import { Template } from 'src/app/enum/template.enum';
import { SessionStorage } from 'src/app/others/sessionstorage';

import { PermissionModel } from 'src/app/model/permission.model';
import { UserModel } from 'src/app/model/user.model';

@Component({
  selector: 'shared-admin-layout',
  templateUrl: './layout.component.html'
})
export class AdminLayoutComponent extends BaseLayoutComponent implements OnInit {
  currentUser: UserModel;
  currentPermission: PermissionModel;
  currentPermissionParent: PermissionModel;
  currentPermissionChildren: PermissionModel[];
  currentPermissionFamily: PermissionModel[];
  
  ngOnInit() {
    this.area = 'admin';
    super.ngOnInit();
    let sss = new SessionStorage();
    let cALC = document.getElementsByClassName('oiAL'); // load 1 time
    if (cALC.length == 0) {
      this.loadClassToDivCommon('oiAL');
      var body = document.getElementsByTagName('body')[0];
      body.classList.add('admin-layout');

      if (!sss.hasValue(sss.adminCurrentUser)) {
        window.location.href = '/login?q=' + window.location.pathname;
      }
    }

    if (!this.currentUser) {
      this.currentUser = sss.getItem(sss.adminCurrentUser);
      this.userService.currentUser$.next(this.currentUser);
    }
    this.currentPermission = this.getCurrentPermission();
    this.currentPermissionParent = this.getCurrentPermissionParent();
    this.currentPermissionChildren = this.getCurrentPermissionChildren();
    this.currentPermissionFamily = this.getCurrentPermissionFamily();

    this.activatedRoute.queryParams.subscribe(params => {
      let bit = this.currentPermission ? this.currentPermission.permissionBit : 0;
      this.http.post(`api/admin/common/ChangeCurrentPermissionBit?bit=${bit}`, bit).subscribe();
      this.cookieService.set('CurrentPermissionBit', bit.toString());

      this.currentPermission = this.getCurrentPermission();
      this.permissionService.changeCurrentPermission(this.currentPermission);
    });
  }

  changeCurrentLanguage(iso_code: string) {
    super.changeCurrentLanguage(iso_code);

    let sss = new SessionStorage();
    this.currentUser = sss.getItem(sss.adminCurrentUser);
    this.changeListPermissionLanguage(this.currentUser.permissions, iso_code);
    this.userService.changeCurrentUser(this.currentUser);
  }

  changestatus = (model: any) => super.changestatus(model, this.currentUser.id);
  changestatus_excute = (model: any) => super.changestatus_excute(model, this.currentUser.id);
  delete = (id: number) => super.delete(id, this.currentUser.id);
  delete_excute = (id: number) => super.delete_excute(id, this.currentUser.id);
  save = (model: any) => super.save(model, this.currentUser.id);
  save_excute(model: any, aftersave: string = '') {
    let bit = this.currentPermission ? this.currentPermission.permissionBit : 0;
    let iduser = bit == Permission.Admin ? 0 : this.currentUser.id;
    super.save_excute(aftersave, model, iduser);
  }
  createCopy = (model: any) => super.createCopy(model, this.currentUser.id);
  createCopy_excute(model: any) {
    let bit = this.currentPermission ? this.currentPermission.permissionBit : 0;
    let iduser = bit == Permission.Admin ? 0 : this.currentUser.id;
    super.createCopy_excute(model, iduser);
  }
  
  getCurrentPermission(): PermissionModel {
    return new PermissionModel([]).getCurrentPermission(this.currentUser);
  }
  getCurrentPermissionParent(): PermissionModel {
    return new PermissionModel([]).getCurrentPermissionParent(this.currentUser);
  }
  getCurrentPermissionChildren(notID: number = 0): PermissionModel[] {
    return new PermissionModel([]).getCurrentPermissionChildren(this.currentUser, notID);
  }
  getCurrentPermissionFamily(): PermissionModel[] {
    return new PermissionModel([]).getCurrentPermissionFamily(this.currentUser);
  }
  changePermissionLanguage(p: PermissionModel, iso_code: string): PermissionModel {
    return new PermissionModel([]).changePermissionLanguage(p, iso_code);
  }
  changeListPermissionLanguage(list: PermissionModel[], iso_code: string): PermissionModel[] {
    return new PermissionModel([]).changeListPermissionLanguage(list, iso_code); // override changeListPermissionLanguage from permissionmodel
  }

  private isActive(p: PermissionModel): boolean {
    return this.router.url == p.url || (this.currentPermission && this.currentPermission.parentId == p.id);
  }
  private isAdminEdit(p: PermissionModel): boolean {
    let templateName = Object.keys(Template).find(key => Template[key] == p.templateId);
    if (templateName && templateName.includes('Edit'))
      return true;
    return false;
  }
  private isAdminInfo(p: PermissionModel): boolean {
    let templateName = Object.keys(Template).find(key => Template[key] == p.templateId);
    if (templateName && templateName.includes('Info'))
      return true;
    return false;
  }
  private isAdminPersonal(p: PermissionModel): boolean {
    return p && p.permissionBit == Permission.Personal;
  }

  private menuToggle() {
    var windowWidth = $(window).width();
    if (windowWidth < 1010) {
      $('body').removeClass('open');
      if (windowWidth < 760) {
        $('#left-panel').slideToggle();
      } else {
        $('#left-panel').toggleClass('open-menu');
      }
    } else {
      $('body').toggleClass('open');
      $('#left-panel').removeClass('open-menu');
    }
  }
}
