export class ContentAnalysisModel {
  views: number;
  projects: number;
  posts: number;
  videos: number;

  constructor() {
    this.views = 0;
    this.projects = 0;
    this.posts = 0;
    this.videos = 0;
  }
}
