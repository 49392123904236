import { SelectModel } from './select.model';
export class SelectsModel {
  name: string;
  models: SelectModel[];

  constructor() {
    this.name = '';
    this.models = [];
  }

  CreateByName(name: string): SelectsModel {
    let result = new SelectsModel();
    result.name = name;
    result.models = [new SelectModel()];
    return result
  }
}
