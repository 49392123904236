import { Location, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { OnInit, Component, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { Title, Meta } from '@angular/platform-browser';
import { Link } from 'src/app/others/link';

import { AppendTo } from 'src/app/enum/appendto.enum';
import { MasterData } from 'src/app/enum/masterdata.enum';
import { Status } from 'src/app/enum/status.enum';

import { Resource } from 'src/app/resource/resource';
import { SessionStorage } from 'src/app/others/sessionstorage';

import { ApiService } from 'src/app/service/api.service';
import { AppSettingsService } from 'src/app/service/appsettings.service';
import { LanguageService } from 'src/app/service/language.service';
import { LoginService } from 'src/app/service/login.service';
import { MasterDataService } from 'src/app/service/masterdata.service';
import { PermissionService } from 'src/app/service/permission.service';
import { ProgressService } from 'src/app/service/progress.service';
import { SubjectService } from 'src/app/service/subject.service';
import { UserService } from 'src/app/service/user.service';

import { AppSettingsModel } from 'src/app/model/appsettings.model';
import { HTMLTagModel } from 'src/app/model/htmltag.model';
import { JsonResultModel } from 'src/app/model/jsonresult.model';
import { LanguageModel } from 'src/app/model/language.model';
import { MasterDataModel } from 'src/app/model/masterdata.model';
import { ScriptTagModel } from 'src/app/model/scripttag.model';
import { SearchParamsModel } from 'src/app/model/searchparams.model';
import { ContentModel } from 'src/app/model/content.model';

@Component({
  selector: 'shared-base-layout',
  template: ``
})
export class BaseLayoutComponent implements OnInit {
  rss: Resource;

  appSettings: AppSettingsModel;
  masterdata: MasterDataModel[] = [];
  languages: LanguageModel[] = [];
  currentLanguage: LanguageModel;

  area: string = '';
  controller: string = '';
  backUrl: string = '';

  listData: any[];
  queryString: SearchParamsModel[] = []
  keyword: string = '';
  page: number = 1;
  pageSize: number = 10;
  p: number = 1;
  pz: number = 10;
  count: number = 0;

  pathnameWithoutQuery = location.pathname.split('?')[0];
  innerWidth = window.innerWidth;
  innerHeight = window.innerHeight;
  private divCommon: HTMLElement;
  private idDivCommon: string = 'commonLK'; // add class load 1 time

  protected baseProgress: NgProgressRef;
  protected loadedProgress = 0;
  protected totalProgress = 0;
  protected incProgress = "0%";

  constructor(
    protected apiService: ApiService,
    protected appSettingsService: AppSettingsService,
    protected languageService: LanguageService,
    protected loginService: LoginService,
    protected masterDataService: MasterDataService,
    protected permissionService: PermissionService,
    protected progressService: ProgressService,
    protected subjectService: SubjectService,
    protected userService: UserService,
    protected cookieService: CookieService,
    protected toastrService: ToastrService,
    protected datePipe: DatePipe,
    protected nglocation: Location,
    protected ngProgress: NgProgress,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected http: HttpClient,
    protected changeDetectorRef: ChangeDetectorRef,
    protected titleService: Title,
    protected metaService: Meta,
    protected linkService: Link
  ) {
    this.divCommon = document.getElementById(this.idDivCommon);
    if (this.divCommon == null) {
      let html: HTMLTagModel = { element: 'div', id: this.idDivCommon };
      this.loadHTMLTag(html);

      this.baseProgress = this.ngProgress.ref('baseProgress');
    }
    let sss = new SessionStorage();
    let currentLanguageCode = this.languageService.getCurrentLanguageCode();
    this.appSettings = sss.getItem(sss.appSettings);
    this.masterdata = sss.getItem(sss.masterdata);
    this.languages = sss.getItem(sss.languages);
    this.currentLanguage = this.languages.find(x => x.isoCode == currentLanguageCode);
    this.rss = this.languageService.getResource(currentLanguageCode);
  }

  ngOnInit() {
    let isocode = this.languageService.getCurrentLanguageCode();
    this.rss = this.languageService.getResource(isocode);
    this.currentLanguage = this.languages.find(x => x.isoCode == isocode);

    this.activatedRoute.queryParamMap.subscribe(paramMap => {
      this.queryString = [];
      let params = (paramMap as any).params as Params;

      this.p = this.page;
      this.pz = this.pageSize;
      
      paramMap.keys.forEach(key => {
        let value = params[key];
        this.queryString.push({ Key: key, Value: value });
        switch (key) {
          case 'keyword':
            this.keyword = value;
            break;
          case 'page':
            this.page = value;
            this.p = value;
            break;
          case 'pageSize':
            this.pageSize = value;
            this.pz = value;
            break;
        }
      });
    });

    this.progressService.progress$.subscribe(p => {
      //if (this.baseProgress) {
      //  this.loaded += p.loaded;
      //  this.total += p.total;
      //  console.log('loaded: ' + this.loaded + ' --- total: ' + this.total);

      //  if (!this.baseProgress.isStarted) {
      //    //this.baseProgress.set(10);
      //  }
      //  if (this.total == this.loaded && this.total > 0) {
      //    this.baseProgress.complete();
      //  } else {
      //    let inc = Math.round(100 * this.loaded / this.total);
      //    this.baseProgress.inc(inc);
      //  }
      //}

      //this.loadedProgress += p.loaded;
      //this.totalProgress += p.total;
      //this.incProgress = Math.round(100 * this.loadedProgress / this.totalProgress) + '%';
      //<div class="progress-bar" style="width: 25%" *ngIf="loadedProgress >= totalProgress  && totalProgress > 0">
      //  <span class="progress-bar-fill" [style.width]="incProgress"></span>
      //</div>
    });
  }

  changeCurrentLanguage(iso_code: string) {
    this.languageService.changeCurrentLanguage(iso_code);
    this.languageService.changeCurrentResource(iso_code);
    this.rss = this.languageService.getResource(iso_code);
    this.currentLanguage = this.languages.find(x => x.isoCode === iso_code);
  }

  changestatusinfo(model: any) {
    if (model.statusId == Status.Activated)
      model.statusId = Status.Deactivated;
    else if (model.statusId == Status.Deactivated)
      model.statusId = Status.Activated;
  }

  changestatus(model: any, iduser: number): Observable<JsonResultModel> {
    return this.apiService.changeStatus(this.area, this.controller, model.id, model.statusId, iduser);
  }

  changestatus_excute(model: any, iduser: number) {
    this.changestatus(model, iduser).subscribe(
      data => {
        model.statusId = data.value;
        this.toastrService.success(this.rss.changestatussuccessfully);
      },
      error => {
        console.log(error);
        this.toastrService.error(this.rss.checktheconsolelog);
      }
    );
  }

  delete(id: number, iduser: number): Observable<any> {
    return this.apiService.delete(this.area, this.controller, this.rss.confirmtodelete, id, iduser);
  }

  delete_excute(id: number, iduser: number) {
    this.delete(id, iduser).subscribe(
      () => {
        this.toastrService.success(this.rss.deletesuccessfully);
        this.backUrl != '' ? this.router.navigate([this.backUrl]) : this.getPage();
      },
      error => {
        console.log(error);
        this.toastrService.error(this.rss.checktheconsolelog);
      }
    );
  }

  save(model: any, iduser: number): Observable<JsonResultModel> {
    return this.apiService.save(this.area, this.controller, model, iduser);
  }

  save_excute(aftersave: string, model: any, iduser: number) {
    this.save(model, iduser).subscribe(
      data => {
        this.toastrService.success(this.rss.savesuccessfully);
        switch (aftersave) {
          case this.rss.savecontinue:
            break;
          case this.rss.saveclose:
            break;
          case this.rss.save:
            this.router.navigate([window.location.pathname], { queryParams: { id: data.value } });
            break;
          default:
            this.router.navigate([(location.pathname + location.search).substr(1)]);
            break;
        }
      },
      error => {
        console.log(error);
        this.toastrService.error(this.rss.checktheconsolelog);
      }
    );
  }

  createCopy(model: any, iduser: number): Observable<JsonResultModel> {
    return this.apiService.save(this.area, this.controller, model, iduser, 'CreateCopy');
  }

  createCopy_excute(model: any, iduser: number) {
    this.createCopy(model, iduser).subscribe(
      data => {
        this.toastrService.success(this.rss.savesuccessfully);
        this.router.navigate([window.location.pathname], { queryParams: { id: data.value } });
      },
      error => {
        console.log(error);
        this.toastrService.error(this.rss.checktheconsolelog);
      }
    );
  }

  getPage() {
    this.apiService.getPage(this.area, this.controller, this.queryString).subscribe(data => {
      this.listData = data.data;
      this.count = data.count;
    });
  }

  contentmodel = new ContentModel([]);
  changeListContentLanguage(list: ContentModel[], iso_code: string): ContentModel[] {
    return this.contentmodel.changeListContentLanguage(list, iso_code);
  }

  private getTemplateName(templateID: number): string {
    if (this.masterdata != []) {
      let masterdata = this.masterdata.find(x => x.value == templateID && x.id == MasterData.Template && x.lang == this.languageService.getCurrentLanguageCode());
      if (masterdata)
        return masterdata.name;
    }
    return '';
  }

  private getLinkName(linkID: number): string {
    if (this.masterdata != []) {
      let masterdata = this.masterdata.find(x => x.value == linkID && x.id == MasterData.Link && x.lang == this.languageService.getCurrentLanguageCode());
      if (masterdata)
        return masterdata.name;
    }
    return '';
  }

  private getStatusName(statusID: number): string {
    if (this.masterdata != []) {
      let masterdata = this.masterdata.find(x => x.value == statusID && x.id == MasterData.Status && x.lang == this.languageService.getCurrentLanguageCode());
      if (masterdata)
        return masterdata.name;
    }
    return '';
  }

  private getPermissionType(permissionBIT: number): string {
    if (this.masterdata && permissionBIT < 5) {
      let masterdata = this.masterdata.find(x => x.value == permissionBIT && x.id == MasterData.Permission && x.lang == this.languageService.getCurrentLanguageCode());
      if (masterdata)
        return masterdata.name;
    }
    return this.rss.isrole;
  }

  private getStatusColor(statusID: number): string {
    switch (statusID) {
      case 2:
        return "red";
      case 3:
        return "green";
      default:
        return "";
    }
  }

  private getBitOperation(v1: number, v2: number): boolean {
    return (v1 & v2) > 0;
  }

  private changePage(page: number) {
    let currentsearch = window.location.search.replace('?', '');
    let urlParams = new URLSearchParams(currentsearch);
    let newpagesearch = 'page=' + page;

    if (urlParams.get('page') != '' && urlParams.get('page') != null) {
      let currentpage = 'page=' + urlParams.get('page');
      currentsearch = currentsearch.replace(currentpage, newpagesearch);
    }
    else currentsearch += '&' + newpagesearch;

    this.page = page;
    this.router.navigateByUrl(window.location.pathname + '?' + currentsearch);
  }

  private absoluteIndex(indexOnPage: number): number {
    return this.pageSize * (this.page - 1) + indexOnPage + 1;
  }

  back() {
    this.nglocation.back();
  }

  loadHTMLTag(html: HTMLTagModel) {
    let node = document.createElement(html.element);
    if (html.id) node.id = html.id;

    // append
    document.body.appendChild(node);
  }

  loadScriptTag(script: ScriptTagModel) {
    let node = document.createElement('script');

    if (script.async) node.async = script.async;
    if (script.id) node.id = script.id;
    if (script.src) node.src = script.src;
    if (script.innerHTML) node.innerHTML = script.innerHTML;

    if (script.charset) node.charset = script.charset;
    else node.charset = 'utf-8';

    if (script.type) node.type = script.type;
    else node.type = 'text/javascript';

    // append
    if (script.appendTo == AppendTo.Head) document.getElementsByTagName('head')[0].appendChild(node);
    else document.body.appendChild(node);
  }

  loadClassToDivCommon(className: string) {
    className += ' ';
    document.getElementById(this.idDivCommon).className += className;
  }
}

//(async () => {
//  // before
//  await delay(1);
//  // after
//})();
