import { Component, OnInit } from '@angular/core';
import { ClientLayoutComponent } from '../layout/layout.component';
import { SessionStorage } from 'src/app/others/sessionstorage';
import { Link } from 'src/app/enum/link.enum';
import { LinkModel } from 'src/app/model/link.model';

@Component({
  selector: 'shared-client-footer',
  templateUrl: './footer.component.html'
})
export class ClientFooterComponent extends ClientLayoutComponent implements OnInit {
  iconSocialNetworks: LinkModel[]; 

  ngOnInit() {
    let that = this;
    that.iconSocialNetworks = [];
    let sss = new SessionStorage();

    let sssLinks = sss.getItem(sss.clientLinks);
    let hasSN = false;
    if (sssLinks != '') {
      let sns = sssLinks.filter(x => x.linkId == Link.SocialNetwork) as LinkModel[];
      if (sns.length > 0) {
        hasSN = true;
        that.iconSocialNetworks = sns.sort((a, b) => 0 - (a.displayOrder > b.displayOrder ? 1 : -1));
      }
    }
    if (!hasSN)
      that.apiService.get<LinkModel[]>('client', 'link', 0, `GetByLinkID/${Link.SocialNetwork}`).subscribe(sns => {
        sss.setClientLinks(sns);
        that.iconSocialNetworks = sns;
      });

    that.subjectService.currentLanguageCode$.subscribe(lang => new LinkModel([]).changeListLinkLanguage(that.iconSocialNetworks, lang));
  }
}
