declare var $: any;
import { delay } from 'q';
import { Component, OnInit } from '@angular/core';
import { BaseLayoutComponent } from '../../base/layout/base-layout.component';
import { ContentModel } from 'src/app/model/content.model';
import { SessionStorage } from 'src/app/others/sessionstorage';
import { LinkModel } from 'src/app/model/link.model';
import { ClientCommonModel } from 'src/app/model/clientcommon.model';

@Component({
  selector: 'shared-client-layout',
  templateUrl: './layout.component.html'
})
export class ClientLayoutComponent extends BaseLayoutComponent implements OnInit {
  currentContent: ContentModel;
  loadFirstChild = false;

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.area = 'client';
    that.baseProgress = that.ngProgress.ref('baseProgress');

    that.load1TimeClientLayout();
    that.getCurrentContent();

    that.subjectService.currentLanguageCode$.subscribe(lang => {
      new ContentModel([]).changeListContentLanguage([that.currentContent], lang);
      that.setSEO(that.currentContent);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.subjectService.finishLoadPage$.subscribe(done => {
      if (done == true && window.location.hash) {
        (async () => {
          await delay(500);
          $('html, body').animate({
            scrollTop: $(window.location.hash).offset().top
          }, 500, 'swing');
        })();
      }
    });
  }

  private getCurrentContent() {
    let that = this;
    let hasCurrentContent = false;
    let sss = new SessionStorage();
    let pathname = location.pathname.split('?')[0];
    if (pathname.startsWith('/')) pathname = pathname.substr(1);
    that.currentContent = new ContentModel([]);

    let sssCommon = sss.getItem(sss.clientCommon) as ClientCommonModel[];
    let sssContents = sss.getItem(sss.clientContents);

    let common = sssCommon.find(x => x.url == pathname);
    if (common) {
      if (sssContents != '') {
        let currentContent = sssContents.find(x => x.id == common.id);
        if (currentContent) {
          new ContentModel([]).changeListContentLanguage([currentContent], that.currentLanguage.isoCode);
          that.currentContent = currentContent;
          that.setSEO(currentContent);
          sss.setItem(sss.currentContent, currentContent);

          hasCurrentContent = true;
        }
      }

      if (!hasCurrentContent)
        that.apiService.get<any>(that.area, 'content', common.id).subscribe(data => {
          let content = data.content as ContentModel;
          let links = data.links as LinkModel[];
          that.currentContent = content;
          that.setSEO(content);
          sss.setItem(sss.currentContent, that.currentContent);

          sss.setClientContents([content]);
          sss.setClientLinks(links);
          sss.setClientContentLinks(content.id, links.map(x => x.id));
        });
    }
  }

  private load1TimeClientLayout() {
    let that = this;
    let cALC = document.getElementsByClassName('oiCL');
    if (cALC.length == 0) {
      that.loadClassToDivCommon('oiCL');

      that.metaService.addTags([
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
        { name: 'viewport', content: 'width=device-width,initial-scale=1,shrink-to-fit=no' },
        { name: 'format-detection', content: 'telephone=no' },
        { name: 'msapplication-tap-highlight', content: 'no' },
        { name: 'theme-color', content: '#000000' }, // Mobile title color
        { name: 'msapplication-navbutton-color', content: '#000000' }, // Mobile title color
        { name: 'apple-mobile-web-app-status-bar-style', content: '#000000' }, // Mobile title color
        { name: 'google-site-verification', content: 'iQpnwWx3ZfQMl8cFA0Knvt3HXCUvu--TUknjIQOX5rw' },
        { name: 'msvalidate.01', content: '344CF8363C06F02FD9252C70548176F0' },
        { name: 'yandex-verification', content: '949234eea9e7ecb1' },
        { name: 'author', content: 'Lam Khai' },
        { name: 'robots', content: 'noodp' }, // Chua SEO
        { name: 'apple-itunes-app', content: 'app-idXXX-XXX-XXX-XXXX' }, // Chua dung
      ]);
      that.linkService.addTag({ rel: 'dns-prefetch', href: 'http://maps.googleapis.com/' }); // tang toc load, co the them nhieu
      that.linkService.addTag({ rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&amp;amp;subset=latin-ext,vietnamese' });

      //LKTODO: doi thanh updateTag
      that.linkService.addTag({ rel: 'alternate', type: 'application/rss+xml', title: '', href: location.origin, hreflang: that.currentLanguage.isoCode });
      that.linkService.addTag({ rel: 'canonical', href: location.href });
    }
  }

  private setSEO(content: ContentModel) {
    let that = this;
    let title = `${content.title} - Lam Khai LK ${(new Date()).getFullYear()}`;
    let currentLang = that.currentLanguage.isoCode;

    that.titleService.setTitle(title);

    that.metaService.updateTag({ name: 'keywords', content: `lamkhai,lam khai,lk,${content.metaKeyword}` });
    that.metaService.updateTag({ name: 'description', content: content.metaDescription });
    that.metaService.updateTag({ name: 'generator', content: title });
    that.metaService.updateTag({ name: 'DC.title', content: title }); // SEO map
    that.metaService.updateTag({ name: 'geo.region', content: currentLang }); // SEO map
    that.metaService.updateTag({ name: 'geo.placename', content: title }); // SEO map
    that.metaService.updateTag({ name: 'apple-mobile-web-app-capable', content: 'yes' }); // Meta Tag Search
    that.metaService.updateTag({ name: 'apple-mobile-web-app-title', content: title }); // Meta Tag Search
    
    that.setSEOTwitter(content, title);
    that.setSEOFacebookOpenGraph(content, title);
    that.setSEOGoogleSchemaorg(content, title);
  }
  private setSEOTwitter(content: ContentModel, title: string) {
    let that = this;
    that.metaService.updateTag({ name: 'twitter:card', content: title });
    that.metaService.updateTag({ name: 'twitter:site', content: location.origin });
    that.metaService.updateTag({ name: 'twitter:creator', content: 'Lam Khai' });
    that.metaService.updateTag({ name: 'twitter:title', content: title });
    that.metaService.updateTag({ name: 'twitter:description', content: content.metaDescription });
    //that.metaService.updateTag({ name: 'twitter:image:src', content: '' }); // Chua lam
  }
  private setSEOFacebookOpenGraph(content: ContentModel, title: string) {
    let that = this;
    that.metaService.updateTag({ property: 'fb:admins', content: '100000441148451' });
    that.metaService.updateTag({ property: 'fb:app_id', content: '2119950768016262' });
    that.metaService.updateTag({ property: 'og:type', content: 'website' });
    that.metaService.updateTag({ property: 'og:url', content: '//demo-site.com/index.html' });
    that.metaService.updateTag({ property: 'og:title', content: title });
    //that.metaService.updateTag({ property: 'og:image', content: '' }); // Chua lam
    that.metaService.updateTag({ property: 'og:description', content: content.metaDescription });
    that.metaService.updateTag({ property: 'og:site_name', content: title });
    that.metaService.updateTag({ property: 'article:author', content: '//www.facebook.com/lamkhai.lk' });
    that.metaService.updateTag({ property: 'article:publisher', content: '//www.facebook.com/lamkhai.lk' });
  }
  private setSEOGoogleSchemaorg(content: ContentModel, title: string) {
    let that = this;
    //that.linkService.addTag({ rel: 'author', href: '//plus.google.com/XXXXXXXXXXXXXX/' }); // Chua lam
    //that.linkService.addTag({ rel: 'publisher', href: '//plus.google.com/XXXXXXXXXXXXXX/' }); // Chua lam
    that.metaService.updateTag({ itemprop: 'name', content: title });
    that.metaService.updateTag({ itemprop: 'description', content: content.metaDescription });
    //that.metaService.updateTag({ itemprop: 'image', content: '' }); // Chua lam
  }

  private logoImage: string = '/myimg/others/logo.png';
  private logoImageEnter = () => this.logoImage = '/myimg/others/logo-blue.png';
  private logoImageLeave = () => this.logoImage = '/myimg/others/logo.png';

  private isCurrentContent(): boolean {
    let item = this.currentContent.listTranslation.find(x => x.url == this.pathnameWithoutQuery);
    return item ? true : false;
  }
}
