import { Component, OnInit } from '@angular/core';
import { ClientLayoutComponent } from '../layout/layout.component';
import { ContentModel } from 'src/app/model/content.model';
import { SessionStorage } from 'src/app/others/sessionstorage';
import { Template } from 'src/app/enum/template.enum';

@Component({
  selector: 'shared-client-header',
  templateUrl: './header.component.html'
})
export class ClientHeaderComponent extends ClientLayoutComponent implements OnInit {
  leftMenu: ContentModel[];
  rightMenu: ContentModel[];
  titleHeader: string = '';

  ngOnInit() {
    let that = this;
    let sss = new SessionStorage();
    that.leftMenu = [];
    that.rightMenu = [];
    that.setMenu(sss);

    that.subjectService.currentLanguageCode$.subscribe(lang => that.changeLanguage(lang));
  }

  private setMenu(sss: SessionStorage) {
    let that = this;
    let sssContents = sss.getItem(sss.clientContents);
    let menuTemplateIds: number[] = [Template.Index, Template.About, Template.Contact, Template.News, Template.Project, Template.Video, Template.Document, Template.Overview];
    let hasMenu = false;
    if (sssContents != '') {
      let menu = sssContents.filter(x => menuTemplateIds.includes(x.templateId)) as ContentModel[];
      if (menu.length >= 3) {
        hasMenu = true;
        that.splitMenu(menu.sort((a, b) => 0 - (a.displayOrder > b.displayOrder ? 1 : -1)));
      }
    }
    if (!hasMenu)
      that.apiService.get<ContentModel[]>('client', 'content', 0, 'GetMenu').subscribe(menu => {
        sss.setClientContents(menu);
        that.splitMenu(menu);
      });
  }
  private splitMenu(menu: ContentModel[]) {
    let that = this;
    let length = menu.length;
    let left = Math.ceil(length / 2);
    let right = length - left;

    menu.forEach((item, index) => {
      if (index < left)
        that.leftMenu.push(item);
      else
        that.rightMenu.push(item);
    });
    that.changeLanguage(that.currentLanguage.isoCode);

    let randomMenu = menu[Math.floor(Math.random() * length) - 1];
    if (randomMenu) that.titleHeader = randomMenu.title;
  }

  private changeLanguage(lang: string) {
    new ContentModel([]).changeListContentLanguage(this.leftMenu, lang);
    new ContentModel([]).changeListContentLanguage(this.rightMenu, lang);
  }
}
