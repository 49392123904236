import { Component, OnInit } from '@angular/core';
import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';
import { MasterData } from 'src/app/enum/masterdata.enum';
import { MasterDataModel } from 'src/app/model/masterdata.model';

@Component({
  selector: 'admin-content',
  templateUrl: './content.component.html'
})
export class AdminContentComponent extends AdminLayoutComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
      that.changeListContentLanguage(that.listData, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
    that.controller = 'content';
    that.activatedRoute.queryParams.subscribe(params => {
      if (!params['templateId']) that.templateId = 0;
      that.loadtemplateids();
      that.queryString = [
        { Key: 'page', Value: that.page },
        { Key: 'pageSize', Value: that.pageSize },
        { Key: 'templateId', Value: that.templateId },
        { Key: 'keyword', Value: that.keyword }
      ];
      that.getPage();
    });
  }

  templateId: number = 0;
  templateids: MasterDataModel[];
  templateid: MasterDataModel[];
  templateidSettings: any = {};
  changetemplateid(item: any) {
    this.templateId = item.value;
  }
  removetemplateid(item: any) {
    this.templateId = 0;
  }
  private loadtemplateids() {
    let that = this;
    that.templateids = that.masterdata.filter(x => x.id == MasterData.Template && x.lang == that.currentLanguage.isoCode && x.value > 0 && !x.name.includes('Admin'));
    that.templateid = [that.templateids.find(x => x.value == that.templateId)];
    that.templateidSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: 'value',
      textField: 'name'
    };
  }
}
