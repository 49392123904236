import { LanguageModel } from './language.model';
import { LinkTranslationModel } from './linktranslation.model';

export class LinkModel {
  id: number;
  name: string;
  detail: string;
  image: string;
  url: string;
  displayOrder: number;
  linkId: number;
  statusId: number;
  targetId: number;

  listTranslation: LinkTranslationModel[];

  constructor(languages: LanguageModel[]) {
    let that = this;

    that.id = 0;
    that.name = '';
    that.detail = '';
    that.image = '';
    that.url = '';
    that.displayOrder = 0;
    that.linkId = 0;
    that.statusId = 3;
    that.targetId = 1;

    that.listTranslation = [];
    languages.forEach(function (language) { that.listTranslation.push(new LinkTranslationModel(language.isoCode)) });
  }

  changeListLinkLanguage(list: LinkModel[], iso_code: string): LinkModel[] {
    if (list && list.length > 0) {
      list.forEach(link => {
        if (link.listTranslation && link.listTranslation.length > 0) {
          let lt = link.listTranslation.find(x => x.lang == iso_code);
          if (lt) {
            link.name = lt.name;
            link.detail = lt.detail;
          }
        }
      });
    }
    return list;
  }
}
