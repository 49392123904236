export class SimpleTagTranslationModel {
  idtag: number;
  lang: string;
  name: string;

  constructor(isoCode: string) {
    this.idtag = 0;
    this.lang = isoCode;
    this.name = '';
  }
}
