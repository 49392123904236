import { Component, OnInit } from '@angular/core';

import { AdminLayoutComponent } from 'src/app/shared/admin/layout/layout.component';

import { MasterData } from 'src/app/enum/masterdata.enum';

import { AdminContentModel } from 'src/app/model/admincontent.model';
import { ContentModel } from 'src/app/model/content.model';
import { ContentTranslationModel } from 'src/app/model/contenttranslation.model';
import { JsonResultModel } from 'src/app/model/jsonresult.model';
import { MasterDataModel } from 'src/app/model/masterdata.model';
import { SelectModel } from 'src/app/model/select.model';
import { SelectsModel } from 'src/app/model/selects.model';
import { SearchParamsModel } from 'src/app/model/searchparams.model';

@Component({
  selector: 'admin-content-info',
  templateUrl: './contentinfo.component.html'
})
export class AdminContentInfoComponent extends AdminLayoutComponent implements OnInit {
  targetids: MasterDataModel[];
  targetid: MasterDataModel[];
  targetidSettings: any = {};
  private changetargetid = (item: any) => this.model.content.targetId = item.value;
  private removetargetid(item: any) {
    let that = this;
    that.targetid = [that.targetids.find(x => x.value == that.model.content.targetId)];
  }
  private loadtargetid() {
    let that = this;
    that.targetids = that.masterdata.filter(x => x.id == MasterData.Target && x.lang == that.currentLanguage.isoCode && x.value > 0);
    that.targetid = [that.targetids.find(x => x.value == that.model.content.targetId)];
    that.targetidSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: 'value',
      textField: 'name'
    };
  }

  templateids: MasterDataModel[];
  templateid: MasterDataModel[];
  templateidSettings: any = {};
  private changetemplateid = (item: any) => this.model.content.templateId = item.value;
  private removetemplateid(item: any) {
    this.model.content.templateId = 0;
  }
  private loadtemplateid() {
    let that = this;
    that.templateids = that.masterdata.filter(x => x.id == MasterData.Template && x.lang == that.currentLanguage.isoCode && x.value > 0 && !x.name.includes('Admin'));
    that.templateid = [that.templateids.find(x => x.value == that.model.content.templateId)];
    that.templateidSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: 'value',
      textField: 'name'
    };
  }

  private checkExist(translation: ContentTranslationModel, type: string) {
    let that = this;
    let params: SearchParamsModel[] = [];
    
    params.push({ Key: 'id', Value: translation.idcontent });
    params.push({ Key: 'lang', Value: translation.lang });
    switch (type) {
      case 'name':
        translation.name = translation.name.trim();
        params.push({ Key: type, Value: translation.name });
        break;
      case 'url':
        translation.url = translation.url.trim();
        params.push({ Key: type, Value: translation.url });
        break;
    };

    that.apiService.countExist(that.area, that.controller, params).subscribe(data => {
      if (data.data != 0) {
        switch (type) {
          case 'name':
            translation.name += ' 1';
            that.toastrService.warning(`${that.rss.name} ${that.rss.existed}`);
            break;
          case 'url':
            translation.url += '-1';
            that.toastrService.warning(`Url ${that.rss.existed}`);
            break;
        }
      }
    });
  }
  private ckeditorConfig = {
    extraPlugins: 'codesnippet',
    contentsCss: [''],
    allowedContent: true
  }
  receiveParent = ($event: SelectModel[]) => this.model.models.find(x => x.name == 'parents').models = $event.filter(x => x.selected);
  receiveTag = ($event: SelectModel[]) => this.model.models.find(x => x.name == 'tags').models = $event.filter(x => x.selected);

  model: AdminContentModel;
  newIAdminContent(): AdminContentModel {
    let that = this;
    let result: AdminContentModel = new AdminContentModel(that.languages);
    result.models.push(new SelectsModel().CreateByName('parents'));
    result.models.push(new SelectsModel().CreateByName('tags'));
    return result;
  }

  ngOnInit() {
    super.ngOnInit();
    let that = this;
    that.controller = 'content';
    that.model = that.newIAdminContent();
    that.model.content.id = +that.activatedRoute.snapshot.queryParams['id']; // for ng-multiselect-dropdown-content-parent
    that.loadtargetid();
    that.loadtemplateid();
    that.backUrl = that.currentPermissionFamily && that.currentPermissionFamily.length > 0 ? that.currentPermissionFamily[0].url : '/admin';

    that.activatedRoute.queryParams.subscribe(params => {
      if (that.model.content.id > 0) {
        that.apiService.get<ContentModel>(that.area, that.controller, that.model.content.id).subscribe(
          content => {
            that.languages.forEach(language => {
              if (!content.listTranslation.find(x => x.lang == language.isoCode))
                content.listTranslation.push(new ContentTranslationModel(language.isoCode));
            });
            that.model.content = content;
            that.loadtargetid();
            that.loadtemplateid();
          },
          error => window.location.href = '/admin'
        );
      }
      else
        that.model.content.id = 0;
    });
    that.subjectService.currentLanguageCode$.subscribe(newLang => {
      that.changePermissionLanguage(that.currentPermission, newLang);
      that.changeListPermissionLanguage(that.currentPermissionFamily, newLang);
    });
    that.subjectService.rss$.subscribe(rss => that.rss = rss);
  }

  saveContent(type: string) {
    let that = this;
    let translation = that.model.content.listTranslation.find(x => x.lang == 'vi');
    if (translation.name.trim() == '') {
      that.toastrService.error(`${that.rss.name} ${that.rss.cantbeblank}`);
      return;
    }

    that.apiService.post<JsonResultModel>(that.area, that.controller, that.model, `PostContent?iduser=${that.currentUser.id}`).subscribe(
      data => {
        that.toastrService.success(that.rss.savesuccessfully);
        switch (type) {
          case that.rss.savecontinue:
            that.router.navigate([window.location.pathname.split('?')[0]]);
            break;
          case that.rss.saveclose:
            that.router.navigate([that.backUrl]);
            break;
          case that.rss.save:
            that.router.navigate([window.location.pathname], { queryParams: { id: data.value } });
            break;
        }
      },
      error => {
        console.log(error);
        that.toastrService.error(that.rss.checktheconsolelog);
      }
    );
  }
}
